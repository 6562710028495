import {
  getDecodedToken,
  getSelectedClientName,
  isAllowed,
  isResolvexMember,
  isClientRoleFeatureAllowed,
  setCurrentUserClientRoles,
} from '@resolvex/resolvex-sdk'
import {
  ClientRoleFeatureAccessKey,
  ClientRoleFeatureKey,
  Privileges,
  useGetCurrentUserClientRolesQuery,
} from 'graphql/gen-types'
import { useCallback, useMemo, useState } from 'react'
import { getContext } from 'utils/store'
import {
  ANALYZE_FEATURE_FLAG,
  RESOURCE_FEATURE_FLAG,
  REASSURANCE_FEATURE_FLAG,
  TABLEAU_FEATURE_FLAG,
  URL_CASEFORM_PATIENTS,
  URL_CASEFORM_RESOURCES,
  URL_CASEFORM_DOWNLOADS,
  URL_ANALYTICS_GENERAL,
  URL_ANALYTICS_RESOURCE,
  URL_ANALYTICS_REASSURANCE,
  URL_ANALYTICS_TABLEAU,
  URL_ADMIN_RESOLVEX,
  URL_ADMIN_BASIC,
  URL_ADMIN_TIMESHEET,
  URL_CASEFORM_PATIENTS_ADD,
  URL_CASEFORM_RESOURCES_ADD,
  ADMIN_TIMESHEET,
  URL_CASEFORM_SESSIONS,
  InitFeatureFlagStatus,
  URL_ADMIN_CLIENT_ROLE,
} from 'utils/variables/constants'

const PRIVILEGES_REQUIRED_ANALYZE = [Privileges.Private]
const PRIVILEGES_REQUIRED_CASEFORM = [Privileges.Public]

export const UseFeatureAccess = (): {
  featureAccess: Record<string, boolean>
  hasFeatureAccess: (pathName: string) => boolean
  isInitFeatureFlagStatus: InitFeatureFlagStatus
  initializeFeatureFlag: () => Promise<void>
} => {
  const [isAnalyzeAllowed, setIsAnalyzeAllowed] = useState<boolean>(true)
  const [isResourceAllowed, setIsResourceAllowed] = useState<boolean>(true)
  const [isReassuranceAllowed, setIsReassuranceAllowed] =
    useState<boolean>(true)
  const [isTableauAllowed, setIsTableauAllowed] = useState<boolean>(true)
  const [isAdminTimesheetAllowed, setIsAdminTimesheetAllowed] =
    useState<boolean>(true)

  const [isInitFeatureFlagStatus, setIsInitFeatureFlagStatus] =
    useState<InitFeatureFlagStatus>(null)

  const {
    role,
    privileges: userPrivileges,
    id: userId,
    otpVerified,
  } = getDecodedToken()
  const { setAlertMessage } = getContext()

  const { data: userClientRole, error: userClientRoleError } =
    useGetCurrentUserClientRolesQuery({
      fetchPolicy: 'network-only',
      variables: {
        getCurrentUserClientRolesArgs: {
          clientName: getSelectedClientName(),
        },
      },
      skip: !userId || !otpVerified,
    })

  const featureAccess: Record<string, boolean> = useMemo(() => {
    if (userClientRoleError) {
      setAlertMessage(
        `Error fetching client roles ${userClientRoleError.message}`
      )
    }

    setCurrentUserClientRoles(userClientRole?.getCurrentUserClientRoles, role)

    const hasPrivilege = (PRIVILEGES: Privileges[]): boolean => {
      return PRIVILEGES.some(
        (privilege: Privileges) =>
          userPrivileges && userPrivileges.includes(privilege)
      )
    }

    const isInitFeatureFlagDone = isInitFeatureFlagStatus === 'LOADED'

    return {
      [URL_CASEFORM_PATIENTS]:
        isInitFeatureFlagDone && hasPrivilege(PRIVILEGES_REQUIRED_CASEFORM),
      [URL_CASEFORM_PATIENTS_ADD]:
        isInitFeatureFlagDone && hasPrivilege(PRIVILEGES_REQUIRED_CASEFORM),
      [URL_CASEFORM_SESSIONS]:
        isInitFeatureFlagDone && hasPrivilege(PRIVILEGES_REQUIRED_CASEFORM),
      [URL_CASEFORM_RESOURCES]:
        isInitFeatureFlagDone &&
        isResourceAllowed &&
        isClientRoleFeatureAllowed(
          ClientRoleFeatureKey.Resources,
          ClientRoleFeatureAccessKey.Read
        ),
      [URL_CASEFORM_RESOURCES_ADD]:
        isInitFeatureFlagDone &&
        isResourceAllowed &&
        isClientRoleFeatureAllowed(
          ClientRoleFeatureKey.Resources,
          ClientRoleFeatureAccessKey.Create
        ),
      [URL_CASEFORM_DOWNLOADS]:
        isInitFeatureFlagDone &&
        hasPrivilege(PRIVILEGES_REQUIRED_CASEFORM) &&
        isClientRoleFeatureAllowed(
          ClientRoleFeatureKey.DownloadsList,
          ClientRoleFeatureAccessKey.Read
        ),
      [URL_ANALYTICS_GENERAL]:
        isInitFeatureFlagDone &&
        hasPrivilege(PRIVILEGES_REQUIRED_ANALYZE) &&
        isAnalyzeAllowed &&
        isClientRoleFeatureAllowed(
          ClientRoleFeatureKey.Dashboards,
          ClientRoleFeatureAccessKey.Read
        ),
      [URL_ANALYTICS_RESOURCE]:
        isInitFeatureFlagDone &&
        hasPrivilege(PRIVILEGES_REQUIRED_ANALYZE) &&
        isResourceAllowed &&
        isClientRoleFeatureAllowed(
          ClientRoleFeatureKey.Dashboards,
          ClientRoleFeatureAccessKey.Read
        ),
      [URL_ANALYTICS_REASSURANCE]:
        isInitFeatureFlagDone &&
        hasPrivilege(PRIVILEGES_REQUIRED_ANALYZE) &&
        isReassuranceAllowed &&
        isClientRoleFeatureAllowed(
          ClientRoleFeatureKey.Dashboards,
          ClientRoleFeatureAccessKey.Read
        ),
      [URL_ANALYTICS_TABLEAU]:
        isInitFeatureFlagDone &&
        hasPrivilege(PRIVILEGES_REQUIRED_ANALYZE) &&
        isTableauAllowed &&
        isClientRoleFeatureAllowed(
          ClientRoleFeatureKey.Dashboards,
          ClientRoleFeatureAccessKey.Read
        ),
      [URL_ADMIN_RESOLVEX]: isInitFeatureFlagDone && isResolvexMember(role),
      [URL_ADMIN_BASIC]:
        isInitFeatureFlagDone &&
        isClientRoleFeatureAllowed(
          ClientRoleFeatureKey.AdminSystemRoleManagement,
          ClientRoleFeatureAccessKey.Read
        ),
      [URL_ADMIN_CLIENT_ROLE]:
        isInitFeatureFlagDone &&
        isClientRoleFeatureAllowed(
          ClientRoleFeatureKey.AdminClientRoleManagement,
          ClientRoleFeatureAccessKey.Read
        ),
      [URL_ADMIN_TIMESHEET]:
        isInitFeatureFlagDone &&
        isAdminTimesheetAllowed &&
        isClientRoleFeatureAllowed(
          ClientRoleFeatureKey.TimesheetsSchedule,
          ClientRoleFeatureAccessKey.Read
        ),
    }

    // eslint-disable-next-line
  }, [
    role,
    userPrivileges,
    isResourceAllowed,
    isAnalyzeAllowed,
    isReassuranceAllowed,
    isTableauAllowed,
    isAdminTimesheetAllowed,
    isInitFeatureFlagStatus,
    userClientRole,
    userClientRoleError,
    // setAlertMessage,
  ])

  const hasFeatureAccess = useCallback(
    (pathName: string) => {
      if (isInitFeatureFlagStatus !== 'LOADED') return false

      return featureAccess[pathName] !== undefined
        ? featureAccess[pathName]
        : true
    },
    [featureAccess, isInitFeatureFlagStatus]
  )

  const initializeFeatureFlag = async () => {
    setIsInitFeatureFlagStatus('LOADING')
    try {
      await isAllowed(ANALYZE_FEATURE_FLAG).then(setIsAnalyzeAllowed)
      await isAllowed(RESOURCE_FEATURE_FLAG).then(setIsResourceAllowed)
      await isAllowed(REASSURANCE_FEATURE_FLAG).then(setIsReassuranceAllowed)
      await isAllowed(TABLEAU_FEATURE_FLAG).then(setIsTableauAllowed)
      await isAllowed(ADMIN_TIMESHEET).then(setIsAdminTimesheetAllowed)
    } catch (e) {
      console.log(e)
    }

    setIsInitFeatureFlagStatus('LOADED')
  }

  return {
    featureAccess,
    hasFeatureAccess,
    initializeFeatureFlag,
    isInitFeatureFlagStatus,
  }
}
