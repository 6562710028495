/* eslint-disable */
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
};

export type AddCaseformSessionByScheduleIdArgs = {
  clientName: Scalars['String'];
  id: Scalars['Float'];
  scheduleType: Scalars['String'];
};

export type AddCenterArgs = {
  altName?: InputMaybe<Scalars['String']>;
  applicationProcedure?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  clientId?: InputMaybe<Scalars['Float']>;
  clientName: Scalars['String'];
  county?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  eligibility?: InputMaybe<Scalars['String']>;
  emailAddress: Scalars['String'];
  faxNumber?: InputMaybe<Scalars['String']>;
  feeScale?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  mailingAddress?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  serviceIds: Array<Scalars['Int']>;
  state: Scalars['String'];
  streetAddress: Scalars['String'];
  websiteAddress: Scalars['String'];
  websiteNotes?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type AddClientArgs = {
  companyName: Scalars['String'];
  primaryContactEmail: Scalars['String'];
  primaryContactName: Scalars['String'];
  primaryContactPhone: Scalars['String'];
};

export type AddClientRoleArgs = {
  clientName?: InputMaybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AddNotificationArgs = {
  clientName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  message: Scalars['String'];
  read?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
  userId: Scalars['Int'];
};

export type AddPatientArgs = {
  assignedUserId: Scalars['Int'];
  clientName: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  ipAddress: Scalars['String'];
  lastName: Scalars['String'];
  middleName: Scalars['String'];
  phone: Scalars['String'];
};

export type AddPatientOutreachReasonArgs = {
  clientName: Scalars['String'];
  compliance: Scalars['String'];
  patientId: Scalars['Int'];
  reasonForCall: Scalars['String'];
  session: Scalars['Int'];
};

export type AddPatientReassuranceStatusArgs = {
  clientName: Scalars['String'];
  endDate: Scalars['String'];
  enteredBy: Scalars['String'];
  note: Scalars['String'];
  patientId: Scalars['Int'];
  startDate: Scalars['String'];
  status: Scalars['String'];
};

export type AddPatientRelatedCallArgs = {
  agency: Scalars['String'];
  callDate: Scalars['String'];
  callType?: InputMaybe<Scalars['String']>;
  clientName: Scalars['String'];
  contact?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  patientId: Scalars['Int'];
  phone: Scalars['String'];
  session: Scalars['Int'];
  timeInOut: Scalars['String'];
  timeOff: Scalars['String'];
  timeOn: Scalars['String'];
  toFrom: Scalars['String'];
  worker: Scalars['String'];
};

export type AddPatientSessionArgs = {
  assignedUserId: Scalars['Int'];
  clientName: Scalars['String'];
  patientId: Scalars['Int'];
  session?: InputMaybe<Scalars['Int']>;
};

export type AddQuicklinkArgs = {
  clientName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdById: Scalars['Float'];
  description?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedById: Scalars['Float'];
  url: Scalars['String'];
};

export type AddScheduleArgs = {
  allDay: Scalars['Boolean'];
  clientName: Scalars['String'];
  createdBy: Scalars['String'];
  dateEnd?: InputMaybe<Scalars['String']>;
  dateStart: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  organizerEmail?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['Float']>;
  primaryAssignedUserId: Scalars['Float'];
  recurrence: Scalars['String'];
  scheduleType: Scalars['String'];
  secondaryAssignedUserId?: InputMaybe<Scalars['Float']>;
  session?: InputMaybe<Scalars['Float']>;
  timezone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  updatedBy: Scalars['String'];
};

export type AddUserArgs = {
  clientId: Scalars['Int'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneExtensionNumber: Scalars['String'];
  phoneName: Scalars['String'];
  selectedClientRoleId: Scalars['Float'];
  selectedRole: Roles;
  workerNumber: Scalars['String'];
};

export type ArchiveUserArgs = {
  userId: Scalars['Int'];
};

export type AssignClientRoleArgs = {
  clientName?: InputMaybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  roleId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['Int'];
};

export type AuthenticateUserArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type CallCountAndPctIncrease = {
  __typename?: 'CallCountAndPctIncrease';
  callCountToday: Scalars['Int'];
  percentIncrease: Scalars['Int'];
};

export type CallCounts = {
  __typename?: 'CallCounts';
  callCount: Scalars['Int'];
  date: Scalars['String'];
};

export type CallCountsByTime = {
  __typename?: 'CallCountsByTime';
  callCount: Scalars['Int'];
  time: Scalars['String'];
};

export type CaseFormAdvanceFilterArgs = {
  dateFrom?: InputMaybe<Scalars['String']>;
  dateTo?: InputMaybe<Scalars['String']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  qualityPercent?: InputMaybe<Scalars['Float']>;
  qualityPercentComparator?: InputMaybe<Scalars['String']>;
  questions?: InputMaybe<Array<CaseFormAdvanceFilterQuestionsArgs>>;
  session?: InputMaybe<Scalars['Float']>;
  timeFrom?: InputMaybe<Scalars['String']>;
  timeTo?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
  workerName?: InputMaybe<Scalars['String']>;
};

export type CaseFormAdvanceFilterQuestionsArgs = {
  id: Scalars['Float'];
  keyword: Scalars['String'];
};

export type Center = {
  __typename?: 'Center';
  address: Scalars['String'];
  altName?: Maybe<Scalars['String']>;
  applicationProcedure?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  clientId?: Maybe<Scalars['Float']>;
  county?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  eligibility?: Maybe<Scalars['String']>;
  emailAddress: Scalars['String'];
  faxNumber?: Maybe<Scalars['String']>;
  feeScale?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  mailingAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  services: Array<Service>;
  state: Scalars['String'];
  streetAddress: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  websiteAddress: Scalars['String'];
  websiteNotes?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type CenterInfo = {
  __typename?: 'CenterInfo';
  address: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  websiteAddress: Scalars['String'];
};

export type CenterPatientAvgTimeByDay = {
  __typename?: 'CenterPatientAvgTimeByDay';
  date: Scalars['String'];
  minutes: Scalars['Int'];
};

export type Client = {
  __typename?: 'Client';
  contactEmail?: Maybe<Scalars['String']>;
  contactPerson?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  number?: Maybe<Scalars['String']>;
};

export type ClientRole = {
  __typename?: 'ClientRole';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['Float']>;
};

/** List of available feature access keys */
export enum ClientRoleFeatureAccessKey {
  Create = 'Create',
  Delete = 'Delete',
  Hidden = 'Hidden',
  Read = 'Read',
  Update = 'Update'
}

/** List of available feature keys */
export enum ClientRoleFeatureKey {
  AdminClientRoleManagement = 'AdminClientRoleManagement',
  AdminSystemRoleManagement = 'AdminSystemRoleManagement',
  CaseformGuidelines = 'CaseformGuidelines',
  Dashboards = 'Dashboards',
  DownloadSessions = 'DownloadSessions',
  DownloadsList = 'DownloadsList',
  Line988 = 'Line988',
  LineAll = 'LineAll',
  LineHousing = 'LineHousing',
  LineJulieValentineCenter = 'LineJulieValentineCenter',
  LineSosLoss = 'LineSosLoss',
  PrintToPdf = 'PrintToPDF',
  Quicklinks = 'Quicklinks',
  ReassuranceCaseformGuidelines = 'ReassuranceCaseformGuidelines',
  Resources = 'Resources',
  TimesheetsFollowUp = 'TimesheetsFollowUp',
  TimesheetsSchedule = 'TimesheetsSchedule'
}

export type ClientRolePrivilege = {
  __typename?: 'ClientRolePrivilege';
  create: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Int']>;
  delete: Scalars['Boolean'];
  feature: ClientRoleFeatureKey;
  hidden: Scalars['Boolean'];
  read: Scalars['Boolean'];
  roleId: Scalars['Int'];
  update: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['Int']>;
};

export type ClientRolePrivilegeArgs = {
  create: Scalars['Boolean'];
  delete: Scalars['Boolean'];
  feature: ClientRoleFeatureKey;
  hidden: Scalars['Boolean'];
  read: Scalars['Boolean'];
  roleId: Scalars['Int'];
  update: Scalars['Boolean'];
};

export type ClientRoleWithAssignedUserId = {
  __typename?: 'ClientRoleWithAssignedUserId';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['Float']>;
  userId: Scalars['Int'];
};

export type ClientRoleWithPrivilege = {
  __typename?: 'ClientRoleWithPrivilege';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  privileges: Array<ClientRolePrivilege>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['Float']>;
};

export type CovidCountAndPctIncrease = {
  __typename?: 'CovidCountAndPctIncrease';
  covidCountToday: Scalars['Int'];
  percentIncrease: Scalars['Int'];
};

export type CovidCounts = {
  __typename?: 'CovidCounts';
  covidCount: Scalars['Int'];
  date: Scalars['String'];
};

export type CreateOrAddToExistingPatientGroupArgs = {
  clientName: Scalars['String'];
  patientIds: Array<Scalars['Int']>;
};

export type CurrentUserClientPrivileges = {
  __typename?: 'CurrentUserClientPrivileges';
  create?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  feature: ClientRoleFeatureKey;
  hidden?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
  update?: Maybe<Scalars['Boolean']>;
};

export type DeleteCenterByIdArgs = {
  centerId: Scalars['Int'];
};

export type DeleteClientArgs = {
  companyName: Scalars['String'];
};

export type DeleteClientRoleArgs = {
  clientName?: InputMaybe<Scalars['String']>;
  roleId: Scalars['Int'];
};

export type DeletePatientArgs = {
  clientName: Scalars['String'];
  patientId: Scalars['Int'];
};

export type DeletePatientOutreachReasonArgs = {
  clientName: Scalars['String'];
  id: Scalars['Int'];
};

export type DeletePatientReassuranceStatusArgs = {
  clientName: Scalars['String'];
  patientId: Scalars['Int'];
  reassuranceStatusId: Scalars['Int'];
};

export type DeletePatientRelatedCallArgs = {
  clientName: Scalars['String'];
  id: Scalars['Int'];
};

export type DeleteQuicklinkByIdArgs = {
  clientName: Scalars['String'];
  id: Scalars['Float'];
};

export type DeleteScheduleArgs = {
  clientName: Scalars['String'];
  id: Scalars['Float'];
  organizerEmail?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type DeleteTokenArgs = {
  token: Scalars['String'];
};

export type DeleteUserArgs = {
  userId: Scalars['Int'];
};

export type EditUserArgs = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneExtensionNumber?: InputMaybe<Scalars['String']>;
  phoneName?: InputMaybe<Scalars['String']>;
  selectedRole: Roles;
  userId: Scalars['Int'];
  workerNumber?: InputMaybe<Scalars['String']>;
};

export type ForgotPasswordArgs = {
  email: Scalars['String'];
};

export type GetAllAnswersByQuestionIdArgs = {
  clientName: Scalars['String'];
  questionId: Scalars['Float'];
};

export type GetAllCaseFormArgs = {
  advanceFilter?: InputMaybe<CaseFormAdvanceFilterArgs>;
  assignedUserId?: InputMaybe<Scalars['Float']>;
  clientName: Scalars['String'];
  filter24hours: Scalars['Boolean'];
  filterGroupId?: InputMaybe<Scalars['Float']>;
  filterMyCaseform: Scalars['Boolean'];
  group?: InputMaybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
  offset: Scalars['Float'];
  textSearch?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type GetAllCaseFormSessionsArgs = {
  advanceFilter?: InputMaybe<CaseFormAdvanceFilterArgs>;
  assignedUserId?: InputMaybe<Scalars['Float']>;
  clientName: Scalars['String'];
  filter24hours: Scalars['Boolean'];
  filterGroupId?: InputMaybe<Scalars['Float']>;
  filterMyCaseform: Scalars['Boolean'];
  group?: InputMaybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
  offset: Scalars['Float'];
  textSearch?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type GetAllCenterByServicesArgs = {
  clientName: Scalars['String'];
  servicesIds: Array<Scalars['Int']>;
};

export type GetAllCentersArgs = {
  clientName: Scalars['String'];
  keyword?: InputMaybe<Scalars['String']>;
  keywordAddress?: InputMaybe<Scalars['String']>;
};

export type GetAllPatientsQuestionAnswerArgs = {
  assignedUserId: Scalars['Float'];
  clientName: Scalars['String'];
  maxRowPerFile?: InputMaybe<Scalars['Float']>;
};

export type GetAllPatientsQuestionAnswerCountArgs = {
  clientName: Scalars['String'];
};

export type GetAllPatientsQuestionAnswerDataArgs = {
  clientName: Scalars['String'];
  limit: Scalars['Float'];
  offset: Scalars['Float'];
};

export type GetAllQuestionsArgs = {
  clientName: Scalars['String'];
};

export type GetAllUsersArgs = {
  clientId: Scalars['Int'];
};

export type GetCaseformQualityPercentArgs = {
  clientName: Scalars['String'];
  patientId: Scalars['Int'];
  session: Scalars['Int'];
};

export type GetCaseformQualityPercentByPatientsArgs = {
  clientName: Scalars['String'];
  patients: Array<QualityPercentPatientsArgs>;
};

export type GetCaseformSessionsWithPatientsAndQuestionsArgs = {
  clientName: Scalars['String'];
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type GetCenterArgs = {
  centerId: Scalars['Int'];
  clientName: Scalars['String'];
};

export type GetCentersInfoByPatientIdArgs = {
  clientName: Scalars['String'];
  patientId: Scalars['Int'];
};

export type GetClientAnalyticsArgs = {
  ageRange?: InputMaybe<Scalars['String']>;
  clientName: Scalars['String'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type GetClientDetailsArgs = {
  clientId: Scalars['Int'];
};

export type GetClientRoleAccessByFeatureKeyArgs = {
  access: ClientRoleFeatureAccessKey;
  feature: ClientRoleFeatureKey;
};

export type GetClientRolePrivilegesArgs = {
  clientName?: InputMaybe<Scalars['String']>;
  roleId: Scalars['Int'];
};

export type GetClientRolesArgs = {
  clientName?: InputMaybe<Scalars['String']>;
};

export type GetClientRolesByRoleIdArgs = {
  clientName?: InputMaybe<Scalars['String']>;
  roleId: Scalars['Int'];
};

export type GetClientRolesByUserIdArgs = {
  clientName?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type GetClientRolesByUserIdsArgs = {
  clientName?: InputMaybe<Scalars['String']>;
  userIds: Array<Scalars['Int']>;
};

export type GetCurrentUserClientRolesArgs = {
  clientName?: InputMaybe<Scalars['String']>;
};

export type GetNotificationsByIdArgs = {
  clientName: Scalars['String'];
  userId: Scalars['Int'];
};

export type GetPatientCenterArgs = {
  clientName: Scalars['String'];
  patientId: Scalars['Int'];
  session: Scalars['Int'];
};

export type GetPatientInfoArgs = {
  clientName: Scalars['String'];
  email: Scalars['String'];
  ipAddress: Scalars['String'];
  phone: Scalars['String'];
};

export type GetPatientInfoByIdArgs = {
  clientName: Scalars['String'];
  patientId: Scalars['Int'];
};

export type GetPatientOutreachReasonArgs = {
  clientName: Scalars['String'];
  patientId: Scalars['Int'];
  session: Scalars['Int'];
};

export type GetPatientQuestionAnswerArgs = {
  clientName: Scalars['String'];
  patientId: Scalars['Int'];
};

export type GetPatientReassuranceStatusArgs = {
  clientName: Scalars['String'];
  patientId: Scalars['Int'];
};

export type GetPatientRelatedCallsArgs = {
  clientName: Scalars['String'];
  patientId: Scalars['Int'];
  session: Scalars['Int'];
};

export type GetPatientSessionsByPatientIdArgs = {
  clientName: Scalars['String'];
  patientId: Scalars['Int'];
};

export type GetPatientsByClientIdArgs = {
  clientId: Scalars['Int'];
};

export type GetQuicklinkByIdArgs = {
  clientName: Scalars['String'];
  id: Scalars['Float'];
};

export type GetQuicklinksArgs = {
  clientName: Scalars['String'];
  itemPerPage?: InputMaybe<Scalars['Float']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Float']>;
};

export type GetScheduleArgs = {
  clientName: Scalars['String'];
  filter?: InputMaybe<GetScheduleFilter>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type GetScheduleByIdArgs = {
  clientName: Scalars['String'];
  id: Scalars['Float'];
};

export type GetScheduleByPatientArgs = {
  clientName: Scalars['String'];
  patientId: Scalars['Float'];
  scheduleType: Scalars['String'];
  session: Scalars['Float'];
};

export type GetScheduleFilter = {
  assignedUsedId?: InputMaybe<Scalars['Float']>;
  month?: InputMaybe<Scalars['Float']>;
  year?: InputMaybe<Scalars['Float']>;
};

export type GetUserByIdArgs = {
  userId: Scalars['Int'];
};

export type GetUsersByIdsArgs = {
  userIds: Array<Scalars['Int']>;
};

export type MostUsedReassuranceCompliance = {
  __typename?: 'MostUsedReassuranceCompliance';
  compliance: Scalars['String'];
  useCount: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addCaseformSessionByScheduleId: PatientNewSessionSet;
  addCenter: Scalars['Boolean'];
  addClient: Scalars['Boolean'];
  addClientRole: ClientRole;
  addNotification: Scalars['Boolean'];
  addPatient: Patient;
  addPatientOutreachReason: Scalars['Boolean'];
  addPatientReassuranceStatus: Scalars['Boolean'];
  addPatientRelatedCall: Scalars['Boolean'];
  addPatientSession: Scalars['Boolean'];
  addQuickLink: Scalars['Boolean'];
  addSchedule: Scalars['Float'];
  addUser: Scalars['Boolean'];
  archiveUser: Scalars['Boolean'];
  assignClientRole: Scalars['Boolean'];
  authenticateUser: Scalars['String'];
  createOrAddToExistingPatientGroup: Scalars['Float'];
  deleteCenterById: Scalars['Boolean'];
  deleteClient: Scalars['Boolean'];
  deleteClientRole: Scalars['Boolean'];
  deleteDataFiles: Scalars['Boolean'];
  deletePatient: Scalars['Boolean'];
  deletePatientOutreachReason: Scalars['Boolean'];
  deletePatientReassuranceStatus: Scalars['Boolean'];
  deletePatientRelatedCall: Scalars['Boolean'];
  deleteQuickLinkById: Scalars['Boolean'];
  deleteSchedule: Scalars['Boolean'];
  deleteToken: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  editUser: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  generateUserOtp: Scalars['String'];
  refreshToken: Scalars['String'];
  removePatientFromPatientGroup: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  setClientRolePrivileges: Scalars['Boolean'];
  setPatientQuestionAnswer: Scalars['String'];
  unarchiveUser: Scalars['Boolean'];
  updateCenter: Scalars['Boolean'];
  updateClientDetails: Scalars['Boolean'];
  updateClientRole: ClientRole;
  updateNotesPatient: Scalars['Boolean'];
  updateNotificationAll: Scalars['Boolean'];
  updateNotificationById: Scalars['Boolean'];
  updatePatient: Scalars['Boolean'];
  updatePatientCenter: Scalars['Boolean'];
  updatePatientOutreachReason: Scalars['Boolean'];
  updatePatientReassuranceStatus: Scalars['Boolean'];
  updatePatientRelatedCall: Scalars['Boolean'];
  updateQuickLinkById: Scalars['Boolean'];
  updateSchedule: Scalars['Boolean'];
  updateUserRole: Scalars['Boolean'];
  verifyUserOtp: Scalars['String'];
};


export type MutationAddCaseformSessionByScheduleIdArgs = {
  addCaseformSessionByScheduleIdArgs: AddCaseformSessionByScheduleIdArgs;
};


export type MutationAddCenterArgs = {
  addCenterArgs: AddCenterArgs;
};


export type MutationAddClientArgs = {
  addClientArgs: AddClientArgs;
};


export type MutationAddClientRoleArgs = {
  addClientRoleArgs: AddClientRoleArgs;
};


export type MutationAddNotificationArgs = {
  addNotificationArgs: AddNotificationArgs;
};


export type MutationAddPatientArgs = {
  addPatientArgs: AddPatientArgs;
};


export type MutationAddPatientOutreachReasonArgs = {
  addPatientOutreachReasonArgs: AddPatientOutreachReasonArgs;
};


export type MutationAddPatientReassuranceStatusArgs = {
  addPatientReassuranceStatusArgs: AddPatientReassuranceStatusArgs;
};


export type MutationAddPatientRelatedCallArgs = {
  addPatientRelatedCallArgs: AddPatientRelatedCallArgs;
};


export type MutationAddPatientSessionArgs = {
  addPatientSessionArgs: AddPatientSessionArgs;
};


export type MutationAddQuickLinkArgs = {
  addQuickLink: AddQuicklinkArgs;
};


export type MutationAddScheduleArgs = {
  addScheduleArgs: AddScheduleArgs;
};


export type MutationAddUserArgs = {
  addUserArgs: AddUserArgs;
};


export type MutationArchiveUserArgs = {
  archiveUserArgs: ArchiveUserArgs;
};


export type MutationAssignClientRoleArgs = {
  assignClientRoleArgs: AssignClientRoleArgs;
};


export type MutationAuthenticateUserArgs = {
  authenticateUserArgs: AuthenticateUserArgs;
};


export type MutationCreateOrAddToExistingPatientGroupArgs = {
  createOrAddToExistingPatientGroupArgs: CreateOrAddToExistingPatientGroupArgs;
};


export type MutationDeleteCenterByIdArgs = {
  deleteCenterByIdArgs: DeleteCenterByIdArgs;
};


export type MutationDeleteClientArgs = {
  deleteClientArgs: DeleteClientArgs;
};


export type MutationDeleteClientRoleArgs = {
  deleteClientRoleArgs: DeleteClientRoleArgs;
};


export type MutationDeleteDataFilesArgs = {
  awsS3DeleteFilesArgs: AwsS3DeleteFilesArgs;
};


export type MutationDeletePatientArgs = {
  deletePatientArgs: DeletePatientArgs;
};


export type MutationDeletePatientOutreachReasonArgs = {
  deletePatientOutreachReasonArgs: DeletePatientOutreachReasonArgs;
};


export type MutationDeletePatientReassuranceStatusArgs = {
  deletePatientReassuranceStatusArgs: DeletePatientReassuranceStatusArgs;
};


export type MutationDeletePatientRelatedCallArgs = {
  deletePatientRelatedCallArgs: DeletePatientRelatedCallArgs;
};


export type MutationDeleteQuickLinkByIdArgs = {
  deleteQuickLinkById: DeleteQuicklinkByIdArgs;
};


export type MutationDeleteScheduleArgs = {
  deleteScheduleArgs: DeleteScheduleArgs;
};


export type MutationDeleteTokenArgs = {
  deleteTokenArgs: DeleteTokenArgs;
};


export type MutationDeleteUserArgs = {
  deleteUserArgs: DeleteUserArgs;
};


export type MutationEditUserArgs = {
  editUserArgs: EditUserArgs;
};


export type MutationForgotPasswordArgs = {
  forgotPasswordArgs: ForgotPasswordArgs;
};


export type MutationRefreshTokenArgs = {
  refreshTokenArgs: RefreshTokenArgs;
};


export type MutationRemovePatientFromPatientGroupArgs = {
  removePatientFromPatientGroupArgs: RemovePatientFromPatientGroupArgs;
};


export type MutationResetPasswordArgs = {
  resetPasswordArgs: ResetPasswordArgs;
};


export type MutationSetClientRolePrivilegesArgs = {
  setClientRolePrivilegesArgs: SetClientRolePrivilegesArgs;
};


export type MutationSetPatientQuestionAnswerArgs = {
  setPatientQuestionAnswerArgs: SetPatientQuestionAnswerArgs;
};


export type MutationUnarchiveUserArgs = {
  unarchiveUserArgs: UnarchiveUserArgs;
};


export type MutationUpdateCenterArgs = {
  updateCenterArgs: UpdateCenterArgs;
};


export type MutationUpdateClientDetailsArgs = {
  updateClientDetailsArgs: UpdateClientDetailsArgs;
};


export type MutationUpdateClientRoleArgs = {
  updateClientRoleArgs: UpdateClientRoleArgs;
};


export type MutationUpdateNotesPatientArgs = {
  updateNotesPatientArgs: UpdateNotesPatientArgs;
};


export type MutationUpdateNotificationAllArgs = {
  updateNotificationAllArgs: UpdateNotificationAllArgs;
};


export type MutationUpdateNotificationByIdArgs = {
  updateNotificationByIdArgs: UpdateNotificationByIdArgs;
};


export type MutationUpdatePatientArgs = {
  updatePatientArgs: UpdatePatientArgs;
};


export type MutationUpdatePatientCenterArgs = {
  updatePatientCenterArgs: UpdatePatientCenterArgs;
};


export type MutationUpdatePatientOutreachReasonArgs = {
  updatePatientOutreachReasonArgs: UpdatePatientOutreachReasonArgs;
};


export type MutationUpdatePatientReassuranceStatusArgs = {
  updatePatientReassuranceStatusArgs: UpdatePatientReassuranceStatusArgs;
};


export type MutationUpdatePatientRelatedCallArgs = {
  updatePatientRelatedCallArgs: UpdatePatientRelatedCallArgs;
};


export type MutationUpdateQuickLinkByIdArgs = {
  updateQuickLinkById: UpdateQuicklinkByIdArgs;
};


export type MutationUpdateScheduleArgs = {
  updateScheduleArgs: UpdateScheduleArgs;
};


export type MutationUpdateUserRoleArgs = {
  updateUserRoleArgs: UpdateUserRoleArgs;
};


export type MutationVerifyUserOtpArgs = {
  verifyUserOtpArgs: VerifyUserOtpArgs;
};

export type Notification = {
  __typename?: 'Notification';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  message: Scalars['String'];
  read?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  userId: Scalars['Int'];
};

export type Patient = {
  __typename?: 'Patient';
  assignedUserId: Scalars['Int'];
  caseNum?: Maybe<Scalars['String']>;
  clientId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  ipAddress?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  requiresFollowUp?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type PatientCaseFormSession = {
  __typename?: 'PatientCaseFormSession';
  assignedUserEmail?: Maybe<Scalars['String']>;
  assignedUserId: Scalars['Int'];
  assignedUserName?: Maybe<Scalars['String']>;
  assignedUserPhoneName?: Maybe<Scalars['String']>;
  caseformDate?: Maybe<Scalars['String']>;
  caseformLineCalled?: Maybe<Scalars['String']>;
  caseformTimeOn?: Maybe<Scalars['String']>;
  contactIpAddress?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  patientId: Scalars['Int'];
  rank?: Maybe<Scalars['Float']>;
  session: Scalars['Int'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedByUserEmail?: Maybe<Scalars['String']>;
  updatedByUserId: Scalars['Int'];
  updatedByUserName?: Maybe<Scalars['String']>;
  updatedByUserPhoneName?: Maybe<Scalars['String']>;
};

export type PatientCenter = {
  __typename?: 'PatientCenter';
  assignedPrimary?: Maybe<Scalars['Boolean']>;
  assignedSecondary?: Maybe<Scalars['Boolean']>;
  centerId: Scalars['Int'];
};

export type PatientNewSessionSet = {
  __typename?: 'PatientNewSessionSet';
  patient: Patient;
  sessionNumber: Scalars['Int'];
};

export type PatientOutreachReason = {
  __typename?: 'PatientOutreachReason';
  compliance: Scalars['String'];
  id: Scalars['Int'];
  patientId: Scalars['Int'];
  reasonForCall: Scalars['String'];
  session: Scalars['Int'];
};

export type PatientQualityChecks = {
  __typename?: 'PatientQualityChecks';
  patientId: Scalars['Int'];
  percent: Scalars['Float'];
  session: Scalars['Int'];
};

export type PatientQuestionAnswer = {
  __typename?: 'PatientQuestionAnswer';
  answer?: Maybe<Scalars['String']>;
  assignedUser: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  patientName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  question: Scalars['String'];
};

export type PatientQuestionAnswerUrl = {
  __typename?: 'PatientQuestionAnswerURL';
  downloadURL: Scalars['String'];
};

export type PatientReassuranceStatus = {
  __typename?: 'PatientReassuranceStatus';
  endDate: Scalars['String'];
  enteredBy: Scalars['String'];
  note: Scalars['String'];
  patientId: Scalars['Int'];
  reassuranceStatusId: Scalars['Int'];
  startDate: Scalars['String'];
  status: Scalars['String'];
};

export type PatientRelatedCall = {
  __typename?: 'PatientRelatedCall';
  agency: Scalars['String'];
  callDate: Scalars['String'];
  callType?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  patientId: Scalars['Int'];
  phone: Scalars['String'];
  session: Scalars['Int'];
  timeInOut: Scalars['String'];
  timeOff: Scalars['String'];
  timeOn: Scalars['String'];
  toFrom: Scalars['String'];
  worker: Scalars['String'];
};

export type PatientSchedule = {
  __typename?: 'PatientSchedule';
  dateEnd?: Maybe<Scalars['String']>;
  dateStart: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  primaryAssignedUserId: Scalars['Float'];
  recurrence: Scalars['String'];
  scheduleType: Scalars['String'];
  secondaryAssignedUserId?: Maybe<Scalars['Float']>;
};

export type PatientSession = {
  __typename?: 'PatientSession';
  assignedUserEmail: Scalars['String'];
  assignedUserId: Scalars['Int'];
  assignedUserName?: Maybe<Scalars['String']>;
  assignedUserPhoneName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  patientId: Scalars['Int'];
  session: Scalars['Int'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedByUserEmail: Scalars['String'];
  updatedByUserName?: Maybe<Scalars['String']>;
  updatedByUserPhoneName?: Maybe<Scalars['String']>;
};

export type PatientSessionQuestionSet = {
  __typename?: 'PatientSessionQuestionSet';
  questionSet: Array<QuestionSet>;
  sessionNumber: Scalars['Int'];
};

export enum Privileges {
  Api = 'API',
  Developer = 'DEVELOPER',
  OtpUnverified = 'OTP_UNVERIFIED',
  Private = 'PRIVATE',
  Protected = 'PROTECTED',
  Public = 'PUBLIC',
  System = 'SYSTEM'
}

export type QualityPercentPatientsArgs = {
  patientId: Scalars['Int'];
  session: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  getAgeDistribution: Array<Scalars['JSONObject']>;
  getAllAnswersByQuestionId: Array<Scalars['String']>;
  getAllCaseForm: Array<Patient>;
  getAllCaseFormSessions: Array<PatientCaseFormSession>;
  getAllCenterByServices: Array<CenterInfo>;
  getAllCenters: Array<CenterInfo>;
  getAllClients: Array<Client>;
  getAllPatientsQuestionAnswer: PatientQuestionAnswerUrl;
  getAllPatientsQuestionAnswerCount: Scalars['Float'];
  getAllPatientsQuestionAnswerData: Array<PatientQuestionAnswer>;
  getAllQuestions: Array<QuestionGrouped>;
  getAllServices: Array<Service>;
  getAllUserRoles: Array<Roles>;
  getAllUsers: Array<User>;
  getCallCountAndPctIncrease: CallCountAndPctIncrease;
  getCallCounts: Array<CallCounts>;
  getCallCountsByTime: Array<CallCountsByTime>;
  getCaseformQualityPercent: Scalars['Float'];
  getCaseformQualityPercentByPatientIds: Array<PatientQualityChecks>;
  getCaseformSessionsWithPatientsAndQuestions: Array<Scalars['JSONObject']>;
  getCenter: Center;
  getCenterPatientAvgTimeByDay: Array<CenterPatientAvgTimeByDay>;
  getCentersInfoByPatientId: Array<Center>;
  getClientDetails: Client;
  getClientRoleAccessByFeatureKey: Scalars['Boolean'];
  getClientRoleById: ClientRole;
  getClientRoleByUserId: ClientRoleWithPrivilege;
  getClientRoleByUserIds: Array<ClientRoleWithAssignedUserId>;
  getClientRolePrivileges: Array<ClientRolePrivilege>;
  getClientRoles: Array<ClientRole>;
  getCovidCountAndPctIncrease: CovidCountAndPctIncrease;
  getCovidCounts: Array<CovidCounts>;
  getCurrentUserClientRoles: Array<CurrentUserClientPrivileges>;
  getMostUsedReassuranceCompliance: Array<MostUsedReassuranceCompliance>;
  getNotificationsById: Array<Notification>;
  getPatientCenters: Array<PatientCenter>;
  getPatientDataFiles: Array<S3Files>;
  getPatientInfo: Array<Patient>;
  getPatientInfoById?: Maybe<Patient>;
  getPatientOutreachReason: Array<PatientOutreachReason>;
  getPatientQuestions: Array<PatientSessionQuestionSet>;
  getPatientReassuranceStatus: Array<PatientReassuranceStatus>;
  getPatientRelatedCalls: Array<PatientRelatedCall>;
  getPatientSessionsByPatientId: Array<PatientSession>;
  getPatientsByClientId: Array<Patient>;
  getQuicklinkById: QuickLink;
  getQuicklinks: Array<QuickLink>;
  getReAssuranceLinePatientByAge: Array<ReAssuranceLinePatientByAge>;
  getReAssuranceLinePatientUseByDay: Array<ReAssuranceLinePatientUseByDay>;
  getScheduleById: ScheduleInfo;
  getScheduleByPatient?: Maybe<PatientSchedule>;
  getSchedules: Array<Schedule>;
  getSevenMostPopularCenters: Array<SevenMostPopularCenters>;
  getSevenMostPopularServices: Array<SevenMostPopularServices>;
  getSuicidalCounts: Array<SuicidalCounts>;
  getSuicideCountAndPctIncrease: SuicideCountAndPctIncrease;
  getTableauToken: Scalars['String'];
  getTopSevenReassuranceReasonForCall: Array<TopSevenReassuranceReasonForCall>;
  getUserById: User;
  getUserSettings: UserSettings;
  getUsersByIds: Array<User>;
  getWorkerAvgTimeByDay: Array<WorkerAvgTimeByDay>;
};


export type QueryGetAgeDistributionArgs = {
  getAgeDistributionArgs: GetClientAnalyticsArgs;
};


export type QueryGetAllAnswersByQuestionIdArgs = {
  getAllAnswersByQuestionIdArgs: GetAllAnswersByQuestionIdArgs;
};


export type QueryGetAllCaseFormArgs = {
  getAllCaseFormArgs: GetAllCaseFormArgs;
};


export type QueryGetAllCaseFormSessionsArgs = {
  getAllCaseFormSessionsArgs: GetAllCaseFormSessionsArgs;
};


export type QueryGetAllCenterByServicesArgs = {
  getAllCenterByServicesArgs: GetAllCenterByServicesArgs;
};


export type QueryGetAllCentersArgs = {
  getAllCentersArgs: GetAllCentersArgs;
};


export type QueryGetAllPatientsQuestionAnswerArgs = {
  getAllPatientsQuestionAnswerArgs: GetAllPatientsQuestionAnswerArgs;
};


export type QueryGetAllPatientsQuestionAnswerCountArgs = {
  getAllPatientsQuestionAnswerArgs: GetAllPatientsQuestionAnswerCountArgs;
};


export type QueryGetAllPatientsQuestionAnswerDataArgs = {
  getAllPatientsQuestionAnswerArgs: GetAllPatientsQuestionAnswerDataArgs;
};


export type QueryGetAllQuestionsArgs = {
  getAllQuestionsArgs: GetAllQuestionsArgs;
};


export type QueryGetAllUsersArgs = {
  getAllUsersArgs: GetAllUsersArgs;
};


export type QueryGetCallCountAndPctIncreaseArgs = {
  getCallCountAndPctIncreaseArgs: GetClientAnalyticsArgs;
};


export type QueryGetCallCountsArgs = {
  getCallCountsArgs: GetClientAnalyticsArgs;
};


export type QueryGetCallCountsByTimeArgs = {
  getCallCountsByTimeArgs: GetClientAnalyticsArgs;
};


export type QueryGetCaseformQualityPercentArgs = {
  getCaseformQualityPercentArgs: GetCaseformQualityPercentArgs;
};


export type QueryGetCaseformQualityPercentByPatientIdsArgs = {
  getCaseformQualityPercentByPatientsArgs: GetCaseformQualityPercentByPatientsArgs;
};


export type QueryGetCaseformSessionsWithPatientsAndQuestionsArgs = {
  getCaseformSessionsWithPatientsAndQuestionsArgs: GetCaseformSessionsWithPatientsAndQuestionsArgs;
};


export type QueryGetCenterArgs = {
  getCenterArgs: GetCenterArgs;
};


export type QueryGetCenterPatientAvgTimeByDayArgs = {
  getCenterPatientAvgTimeByDayArgs: GetClientAnalyticsArgs;
};


export type QueryGetCentersInfoByPatientIdArgs = {
  getCentersInfoByPatientIdArgs: GetCentersInfoByPatientIdArgs;
};


export type QueryGetClientDetailsArgs = {
  getClientDetailsArgs: GetClientDetailsArgs;
};


export type QueryGetClientRoleAccessByFeatureKeyArgs = {
  getClientRoleAccessByFeatureKeyArgs: GetClientRoleAccessByFeatureKeyArgs;
};


export type QueryGetClientRoleByIdArgs = {
  getClientRoleByIdArgs: GetClientRolesByRoleIdArgs;
};


export type QueryGetClientRoleByUserIdArgs = {
  getClientRolesByUserIdArgs: GetClientRolesByUserIdArgs;
};


export type QueryGetClientRoleByUserIdsArgs = {
  getClientRolesByUserIdsArgs: GetClientRolesByUserIdsArgs;
};


export type QueryGetClientRolePrivilegesArgs = {
  getClientRolePrivilegesArgs: GetClientRolePrivilegesArgs;
};


export type QueryGetClientRolesArgs = {
  getClientRolesArgs: GetClientRolesArgs;
};


export type QueryGetCovidCountAndPctIncreaseArgs = {
  getCovidCountAndPctIncreaseArgs: GetClientAnalyticsArgs;
};


export type QueryGetCovidCountsArgs = {
  getCovidCountsArgs: GetClientAnalyticsArgs;
};


export type QueryGetCurrentUserClientRolesArgs = {
  getCurrentUserClientRolesArgs: GetCurrentUserClientRolesArgs;
};


export type QueryGetMostUsedReassuranceComplianceArgs = {
  getMostUsedReassuranceComplianceArgs: GetClientAnalyticsArgs;
};


export type QueryGetNotificationsByIdArgs = {
  getNotificationsByIdArgs: GetNotificationsByIdArgs;
};


export type QueryGetPatientCentersArgs = {
  getPatientCenterArgs: GetPatientCenterArgs;
};


export type QueryGetPatientDataFilesArgs = {
  awsS3DefaultArgs: AwsS3DefaultArgs;
};


export type QueryGetPatientInfoArgs = {
  getPatientInfoArgs: GetPatientInfoArgs;
};


export type QueryGetPatientInfoByIdArgs = {
  getPatientInfoByIdArgs: GetPatientInfoByIdArgs;
};


export type QueryGetPatientOutreachReasonArgs = {
  getPatientOutreachReasonArgs: GetPatientOutreachReasonArgs;
};


export type QueryGetPatientQuestionsArgs = {
  getPatientQuestionAnswerArgs: GetPatientQuestionAnswerArgs;
};


export type QueryGetPatientReassuranceStatusArgs = {
  getPatientReassuranceStatusArgs: GetPatientReassuranceStatusArgs;
};


export type QueryGetPatientRelatedCallsArgs = {
  getPatientRelatedCallsArgs: GetPatientRelatedCallsArgs;
};


export type QueryGetPatientSessionsByPatientIdArgs = {
  getPatientSessionsByPatientIdArgs: GetPatientSessionsByPatientIdArgs;
};


export type QueryGetPatientsByClientIdArgs = {
  getPatientsByClientIdArgs: GetPatientsByClientIdArgs;
};


export type QueryGetQuicklinkByIdArgs = {
  getQuicklinkById: GetQuicklinkByIdArgs;
};


export type QueryGetQuicklinksArgs = {
  getQuicklinks: GetQuicklinksArgs;
};


export type QueryGetReAssuranceLinePatientByAgeArgs = {
  getReAssuranceLinePatientByAgeArgs: GetClientAnalyticsArgs;
};


export type QueryGetReAssuranceLinePatientUseByDayArgs = {
  getReAssuranceLinePatientUseByDayArgs: GetClientAnalyticsArgs;
};


export type QueryGetScheduleByIdArgs = {
  getScheduleByIdArgs: GetScheduleByIdArgs;
};


export type QueryGetScheduleByPatientArgs = {
  getScheduleByPatientArgs: GetScheduleByPatientArgs;
};


export type QueryGetSchedulesArgs = {
  getScheduleArgs: GetScheduleArgs;
};


export type QueryGetSevenMostPopularCentersArgs = {
  getSevenMostPopularCentersArgs: GetClientAnalyticsArgs;
};


export type QueryGetSevenMostPopularServicesArgs = {
  getSevenMostPopularServicesArgs: GetClientAnalyticsArgs;
};


export type QueryGetSuicidalCountsArgs = {
  getSuicidalCountsArgs: GetClientAnalyticsArgs;
};


export type QueryGetSuicideCountAndPctIncreaseArgs = {
  getSuicideCountAndPctIncreaseArgs: GetClientAnalyticsArgs;
};


export type QueryGetTopSevenReassuranceReasonForCallArgs = {
  getTopSevenReassuranceReasonForCallArgs: GetClientAnalyticsArgs;
};


export type QueryGetUserByIdArgs = {
  getUserByIdArgs: GetUserByIdArgs;
};


export type QueryGetUsersByIdsArgs = {
  getUsersByIdsArgs: GetUsersByIdsArgs;
};


export type QueryGetWorkerAvgTimeByDayArgs = {
  getWorkerAvgTimeByDayArgs: GetClientAnalyticsArgs;
};

export type Question = {
  __typename?: 'Question';
  category: Scalars['String'];
  id: Scalars['Int'];
  question: Scalars['String'];
  questionType: QuestionTypesEnum;
};

export type QuestionGrouped = {
  __typename?: 'QuestionGrouped';
  category: Scalars['String'];
  questions: Array<Question>;
};

export type QuestionSet = {
  __typename?: 'QuestionSet';
  answer: Scalars['String'];
  id: Scalars['Int'];
  question: Scalars['String'];
};

export enum QuestionTypesEnum {
  Checkbox = 'CHECKBOX',
  Dropdown = 'DROPDOWN',
  Text = 'TEXT',
  YesOrNo = 'YES_OR_NO'
}

export type QuickLink = {
  __typename?: 'QuickLink';
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  createdById: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedBy: Scalars['String'];
  updatedById: Scalars['Float'];
  url: Scalars['String'];
};

export type ReAssuranceLinePatientByAge = {
  __typename?: 'ReAssuranceLinePatientByAge';
  age: Scalars['String'];
  useCount: Scalars['Int'];
};

export type ReAssuranceLinePatientUseByDay = {
  __typename?: 'ReAssuranceLinePatientUseByDay';
  date: Scalars['String'];
  useCount: Scalars['Int'];
};

export type RefreshTokenArgs = {
  token: Scalars['String'];
};

export type RemovePatientFromPatientGroupArgs = {
  clientName: Scalars['String'];
  groupId: Scalars['Int'];
  patientId: Scalars['Int'];
};

export type ResetPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export enum Roles {
  ClientAdmin = 'CLIENT_ADMIN',
  ClientMember = 'CLIENT_MEMBER',
  ClientSales = 'CLIENT_SALES',
  None = 'NONE',
  ResolvexAdmin = 'RESOLVEX_ADMIN',
  ResolvexMember = 'RESOLVEX_MEMBER'
}

export type S3Files = {
  __typename?: 'S3Files';
  dateModified?: Maybe<Scalars['DateTime']>;
  fileSize?: Maybe<Scalars['Float']>;
  url: Scalars['String'];
};

export type Schedule = {
  __typename?: 'Schedule';
  allDay?: Maybe<Scalars['Boolean']>;
  dateEnd?: Maybe<Scalars['String']>;
  dateStart: Scalars['String'];
  id: Scalars['Int'];
  primaryAssignedUserId?: Maybe<Scalars['Float']>;
  recurrence?: Maybe<Scalars['String']>;
  scheduleType: Scalars['String'];
  secondaryAssignedUserId?: Maybe<Scalars['Float']>;
  title: Scalars['String'];
};

export type ScheduleInfo = {
  __typename?: 'ScheduleInfo';
  allDay?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  dateEnd?: Maybe<Scalars['String']>;
  dateStart: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  primaryAssignedUserId?: Maybe<Scalars['Float']>;
  recurrence?: Maybe<Scalars['String']>;
  scheduleType: Scalars['String'];
  secondaryAssignedUserId?: Maybe<Scalars['Float']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedBy: Scalars['String'];
};

export type Service = {
  __typename?: 'Service';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type SetClientRolePrivilegesArgs = {
  clientName?: InputMaybe<Scalars['String']>;
  privileges: Array<ClientRolePrivilegeArgs>;
  updatedAt: Scalars['DateTime'];
};

export type SetPatientQuestionAnswerArgs = {
  clientName: Scalars['String'];
  editedAnswer: Scalars['String'];
  patientId: Scalars['Int'];
  questionId: Scalars['Int'];
  sessionNumber: Scalars['Int'];
};

export type SevenMostPopularCenters = {
  __typename?: 'SevenMostPopularCenters';
  centerUseCount: Scalars['Int'];
  name: Scalars['String'];
};

export type SevenMostPopularServices = {
  __typename?: 'SevenMostPopularServices';
  name: Scalars['String'];
  serviceUseCount: Scalars['Int'];
};

export type SuicidalCounts = {
  __typename?: 'SuicidalCounts';
  date: Scalars['String'];
  suicidalCount: Scalars['Int'];
};

export type SuicideCountAndPctIncrease = {
  __typename?: 'SuicideCountAndPctIncrease';
  percentIncrease: Scalars['Int'];
  suicideCountToday: Scalars['Int'];
};

export type TopSevenReassuranceReasonForCall = {
  __typename?: 'TopSevenReassuranceReasonForCall';
  reason: Scalars['String'];
  useCount: Scalars['Int'];
};

export type UnarchiveUserArgs = {
  userId: Scalars['Int'];
};

export type UpdateCenterArgs = {
  altName?: InputMaybe<Scalars['String']>;
  applicationProcedure?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  clientId?: InputMaybe<Scalars['Float']>;
  clientName: Scalars['String'];
  county?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  eligibility?: InputMaybe<Scalars['String']>;
  emailAddress: Scalars['String'];
  faxNumber?: InputMaybe<Scalars['String']>;
  feeScale?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  mailingAddress?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  serviceIds: Array<Scalars['Int']>;
  state: Scalars['String'];
  streetAddress: Scalars['String'];
  updatedBy?: InputMaybe<Scalars['String']>;
  websiteAddress: Scalars['String'];
  websiteNotes?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type UpdateClientDetailsArgs = {
  clientId: Scalars['Int'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export type UpdateClientRoleArgs = {
  clientName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  roleId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type UpdateNotesPatientArgs = {
  assignedUserId?: InputMaybe<Scalars['Int']>;
  clientId?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  notes: Scalars['String'];
  patientId: Scalars['Int'];
  phone?: InputMaybe<Scalars['String']>;
};

export type UpdateNotificationAllArgs = {
  clientName: Scalars['String'];
  read?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateNotificationByIdArgs = {
  clientName: Scalars['String'];
  id: Scalars['Float'];
  message: Scalars['String'];
  read?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
  userId: Scalars['Int'];
};

export type UpdatePatientArgs = {
  assignedUserId?: InputMaybe<Scalars['Int']>;
  clientId?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  patientId: Scalars['Int'];
  phone?: InputMaybe<Scalars['String']>;
};

export type UpdatePatientCenterArgs = {
  assignedPrimary?: InputMaybe<Scalars['Boolean']>;
  assignedSecondary?: InputMaybe<Scalars['Boolean']>;
  centerId: Scalars['Int'];
  clientName: Scalars['String'];
  patientId: Scalars['Int'];
  session: Scalars['Int'];
};

export type UpdatePatientOutreachReasonArgs = {
  clientName: Scalars['String'];
  compliance: Scalars['String'];
  id: Scalars['Int'];
  patientId: Scalars['Int'];
  reasonForCall: Scalars['String'];
  session: Scalars['Int'];
};

export type UpdatePatientReassuranceStatusArgs = {
  clientName: Scalars['String'];
  endDate: Scalars['String'];
  enteredBy: Scalars['String'];
  note: Scalars['String'];
  patientId: Scalars['Int'];
  reassuranceStatusId: Scalars['Int'];
  startDate: Scalars['String'];
  status: Scalars['String'];
};

export type UpdatePatientRelatedCallArgs = {
  agency: Scalars['String'];
  callDate: Scalars['String'];
  callType?: InputMaybe<Scalars['String']>;
  clientName: Scalars['String'];
  contact?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  patientId: Scalars['Int'];
  phone: Scalars['String'];
  session: Scalars['Int'];
  timeInOut: Scalars['String'];
  timeOff: Scalars['String'];
  timeOn: Scalars['String'];
  toFrom: Scalars['String'];
  worker: Scalars['String'];
};

export type UpdateQuicklinkByIdArgs = {
  clientName: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Float'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedById: Scalars['Float'];
  url: Scalars['String'];
};

export type UpdateScheduleArgs = {
  allDay: Scalars['Boolean'];
  clientName: Scalars['String'];
  createdBy: Scalars['String'];
  dateEnd?: InputMaybe<Scalars['String']>;
  dateStart: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Float'];
  organizerEmail?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['Float']>;
  primaryAssignedUserId: Scalars['Float'];
  recurrence: Scalars['String'];
  scheduleType: Scalars['String'];
  secondaryAssignedUserId?: InputMaybe<Scalars['Float']>;
  session?: InputMaybe<Scalars['Float']>;
  timezone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  updatedBy: Scalars['String'];
};

export type UpdateUserRoleArgs = {
  newRole: Scalars['String'];
  userId: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  archived?: Maybe<Scalars['Boolean']>;
  clientId: Scalars['Int'];
  clientName: Scalars['String'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  otpEnabled?: Maybe<Scalars['Boolean']>;
  otpSecretHash?: Maybe<Scalars['String']>;
  otpVerified?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  phoneExtensionNumber?: Maybe<Scalars['String']>;
  phoneName?: Maybe<Scalars['String']>;
  privileges: Array<Privileges>;
  role: Roles;
  workerNumber?: Maybe<Scalars['String']>;
};

export type UserSettings = {
  __typename?: 'UserSettings';
  isOtpEnabled: Scalars['Boolean'];
};

export type VerifyUserOtpArgs = {
  otp: Scalars['String'];
  test?: InputMaybe<Scalars['Boolean']>;
};

export type WorkerAvgTimeByDay = {
  __typename?: 'WorkerAvgTimeByDay';
  date: Scalars['String'];
  minutes: Scalars['Int'];
};

export type AwsS3DefaultArgs = {
  clientName: Scalars['String'];
};

export type AwsS3DeleteFilesArgs = {
  fileNames: Array<Scalars['String']>;
};

export type GetPatientsByClientIdQueryVariables = Exact<{
  getPatientsByClientIdArgs: GetPatientsByClientIdArgs;
}>;


export type GetPatientsByClientIdQuery = { __typename?: 'Query', getPatientsByClientId: Array<{ __typename?: 'Patient', id: number, assignedUserId: number, firstName?: string | null | undefined, lastName?: string | null | undefined, email?: string | null | undefined, phone?: string | null | undefined, ipAddress?: string | null | undefined, clientId: number, caseNum?: string | null | undefined, middleName?: string | null | undefined, notes?: string | null | undefined }> };

export type GetAllClientsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllClientsQuery = { __typename?: 'Query', getAllClients: Array<{ __typename?: 'Client', id: number, name: string }> };

export type GetClientDetailsQueryVariables = Exact<{
  getClientDetailsArgs: GetClientDetailsArgs;
}>;


export type GetClientDetailsQuery = { __typename?: 'Query', getClientDetails: { __typename?: 'Client', id: number, name: string, contactEmail?: string | null | undefined, number?: string | null | undefined, contactPerson?: string | null | undefined } };

export type AddClientMutationVariables = Exact<{
  addClientArgs: AddClientArgs;
}>;


export type AddClientMutation = { __typename?: 'Mutation', addClient: boolean };

export type UpdateClientDetailsMutationVariables = Exact<{
  updateClientDetailsArgs: UpdateClientDetailsArgs;
}>;


export type UpdateClientDetailsMutation = { __typename?: 'Mutation', updateClientDetails: boolean };

export type DeleteClientMutationVariables = Exact<{
  deleteClientArgs: DeleteClientArgs;
}>;


export type DeleteClientMutation = { __typename?: 'Mutation', deleteClient: boolean };

export type AddClientRoleMutationVariables = Exact<{
  addClientRoleArgs: AddClientRoleArgs;
}>;


export type AddClientRoleMutation = { __typename?: 'Mutation', addClientRole: { __typename?: 'ClientRole', id: number, name: string, isDefault?: boolean | null | undefined, createdBy?: number | null | undefined, updatedBy?: number | null | undefined, createdAt: any, updatedAt: any } };

export type GetClientRolesQueryVariables = Exact<{
  getClientRolesArgs: GetClientRolesArgs;
}>;


export type GetClientRolesQuery = { __typename?: 'Query', getClientRoles: Array<{ __typename?: 'ClientRole', id: number, name: string, isDefault?: boolean | null | undefined, createdBy?: number | null | undefined, updatedBy?: number | null | undefined, createdAt: any, updatedAt: any }> };

export type GetClientRoleByIdQueryVariables = Exact<{
  getClientRoleByIdArgs: GetClientRolesByRoleIdArgs;
}>;


export type GetClientRoleByIdQuery = { __typename?: 'Query', getClientRoleById: { __typename?: 'ClientRole', id: number, name: string, createdBy?: number | null | undefined, updatedBy?: number | null | undefined, createdAt: any, updatedAt: any } };

export type GetClientRoleByUserIdQueryVariables = Exact<{
  getClientRolesByUserIdArgs: GetClientRolesByUserIdArgs;
}>;


export type GetClientRoleByUserIdQuery = { __typename?: 'Query', getClientRoleByUserId: { __typename?: 'ClientRoleWithPrivilege', id: number, name: string, isDefault?: boolean | null | undefined, createdBy?: number | null | undefined, updatedBy?: number | null | undefined, createdAt: any, updatedAt: any, privileges: Array<{ __typename?: 'ClientRolePrivilege', roleId: number, feature: ClientRoleFeatureKey, create: boolean, read: boolean, update: boolean, delete: boolean, hidden: boolean, createdBy?: number | null | undefined, updatedBy?: number | null | undefined, createdAt: any, updatedAt: any }> } };

export type GetClientRoleByUserIdsQueryVariables = Exact<{
  getClientRolesByUserIdsArgs: GetClientRolesByUserIdsArgs;
}>;


export type GetClientRoleByUserIdsQuery = { __typename?: 'Query', getClientRoleByUserIds: Array<{ __typename?: 'ClientRoleWithAssignedUserId', id: number, userId: number, name: string, isDefault?: boolean | null | undefined, createdBy?: number | null | undefined, updatedBy?: number | null | undefined, createdAt: any, updatedAt: any }> };

export type GetClientRolePrivilegesQueryVariables = Exact<{
  getClientRolePrivilegesArgs: GetClientRolePrivilegesArgs;
}>;


export type GetClientRolePrivilegesQuery = { __typename?: 'Query', getClientRolePrivileges: Array<{ __typename?: 'ClientRolePrivilege', roleId: number, feature: ClientRoleFeatureKey, create: boolean, read: boolean, update: boolean, delete: boolean, hidden: boolean, createdBy?: number | null | undefined, updatedBy?: number | null | undefined, createdAt: any, updatedAt: any }> };

export type UpdateClientRoleMutationVariables = Exact<{
  updateClientRoleArgs: UpdateClientRoleArgs;
}>;


export type UpdateClientRoleMutation = { __typename?: 'Mutation', updateClientRole: { __typename?: 'ClientRole', id: number, name: string, isDefault?: boolean | null | undefined, createdBy?: number | null | undefined, updatedBy?: number | null | undefined, createdAt: any, updatedAt: any } };

export type SetClientRolePrivilegesMutationVariables = Exact<{
  setClientRolePrivilegesArgs: SetClientRolePrivilegesArgs;
}>;


export type SetClientRolePrivilegesMutation = { __typename?: 'Mutation', setClientRolePrivileges: boolean };

export type AssignClientRoleMutationVariables = Exact<{
  assignClientRoleArgs: AssignClientRoleArgs;
}>;


export type AssignClientRoleMutation = { __typename?: 'Mutation', assignClientRole: boolean };

export type DeleteClientRoleMutationVariables = Exact<{
  deleteClientRoleArgs: DeleteClientRoleArgs;
}>;


export type DeleteClientRoleMutation = { __typename?: 'Mutation', deleteClientRole: boolean };

export type GetClientRoleAccessByFeatureKeyQueryVariables = Exact<{
  getClientRoleAccessByFeatureKeyArgs: GetClientRoleAccessByFeatureKeyArgs;
}>;


export type GetClientRoleAccessByFeatureKeyQuery = { __typename?: 'Query', getClientRoleAccessByFeatureKey: boolean };

export type GetCurrentUserClientRolesQueryVariables = Exact<{
  getCurrentUserClientRolesArgs: GetCurrentUserClientRolesArgs;
}>;


export type GetCurrentUserClientRolesQuery = { __typename?: 'Query', getCurrentUserClientRoles: Array<{ __typename?: 'CurrentUserClientPrivileges', feature: ClientRoleFeatureKey, create?: boolean | null | undefined, read?: boolean | null | undefined, update?: boolean | null | undefined, delete?: boolean | null | undefined }> };

export type AuthenticateUserMutationVariables = Exact<{
  authenticateUserArgs: AuthenticateUserArgs;
}>;


export type AuthenticateUserMutation = { __typename?: 'Mutation', authenticateUser: string };

export type VerifyUserOtpMutationVariables = Exact<{
  verifyUserOtpArgs: VerifyUserOtpArgs;
}>;


export type VerifyUserOtpMutation = { __typename?: 'Mutation', verifyUserOtp: string };

export type ResetPasswordMutationVariables = Exact<{
  resetPasswordArgs: ResetPasswordArgs;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: boolean };

export type RefreshTokenMutationVariables = Exact<{
  refreshTokenArgs: RefreshTokenArgs;
}>;


export type RefreshTokenMutation = { __typename?: 'Mutation', refreshToken: string };

export type DeleteTokenMutationVariables = Exact<{
  deleteTokenArgs: DeleteTokenArgs;
}>;


export type DeleteTokenMutation = { __typename?: 'Mutation', deleteToken: boolean };

export type GetNotificationsByIdQueryVariables = Exact<{
  getNotificationsByIdArgs: GetNotificationsByIdArgs;
}>;


export type GetNotificationsByIdQuery = { __typename?: 'Query', getNotificationsById: Array<{ __typename?: 'Notification', id: number, userId: number, title: string, message: string, read?: boolean | null | undefined, url?: string | null | undefined, createdAt: any, updatedAt?: any | null | undefined }> };

export type UpdateNotificationByIdMutationVariables = Exact<{
  updateNotificationByIdArgs: UpdateNotificationByIdArgs;
}>;


export type UpdateNotificationByIdMutation = { __typename?: 'Mutation', updateNotificationById: boolean };

export type UpdateNotificationAllMutationVariables = Exact<{
  updateNotificationAllArgs: UpdateNotificationAllArgs;
}>;


export type UpdateNotificationAllMutation = { __typename?: 'Mutation', updateNotificationAll: boolean };

export type GetQuicklinksQueryVariables = Exact<{
  getQuicklinksArgs: GetQuicklinksArgs;
}>;


export type GetQuicklinksQuery = { __typename?: 'Query', getQuicklinks: Array<{ __typename?: 'QuickLink', id: number, title: string, url: string, description?: string | null | undefined, createdById: number, createdBy: string, updatedById: number, updatedBy: string, createdAt: any, updatedAt: any }> };

export type GetQuicklinkByIdQueryVariables = Exact<{
  getQuicklinkByIdArgs: GetQuicklinkByIdArgs;
}>;


export type GetQuicklinkByIdQuery = { __typename?: 'Query', getQuicklinkById: { __typename?: 'QuickLink', id: number, title: string, url: string, description?: string | null | undefined, createdById: number, createdBy: string, updatedById: number, updatedBy: string, createdAt: any, updatedAt: any } };

export type UpdateQuickLinkByIdMutationVariables = Exact<{
  updateQuicklinkByIdArgs: UpdateQuicklinkByIdArgs;
}>;


export type UpdateQuickLinkByIdMutation = { __typename?: 'Mutation', updateQuickLinkById: boolean };

export type AddQuickLinkMutationVariables = Exact<{
  addQuicklinkArgs: AddQuicklinkArgs;
}>;


export type AddQuickLinkMutation = { __typename?: 'Mutation', addQuickLink: boolean };

export type DeleteQuickLinkByIdMutationVariables = Exact<{
  deleteQuicklinkByIdArgs: DeleteQuicklinkByIdArgs;
}>;


export type DeleteQuickLinkByIdMutation = { __typename?: 'Mutation', deleteQuickLinkById: boolean };

export type GetAllUsersQueryVariables = Exact<{
  getAllUsersArgs: GetAllUsersArgs;
}>;


export type GetAllUsersQuery = { __typename?: 'Query', getAllUsers: Array<{ __typename?: 'User', id: number, clientId: number, clientName: string, firstName?: string | null | undefined, lastName?: string | null | undefined, email: string, privileges: Array<Privileges>, role: Roles }> };

export type GetAllUserRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUserRolesQuery = { __typename?: 'Query', getAllUserRoles: Array<Roles> };

export type GetUserByIdQueryVariables = Exact<{
  getUserByIdArgs: GetUserByIdArgs;
}>;


export type GetUserByIdQuery = { __typename?: 'Query', getUserById: { __typename?: 'User', id: number, clientId: number, clientName: string, firstName?: string | null | undefined, lastName?: string | null | undefined, email: string, privileges: Array<Privileges>, role: Roles } };

export type ForgotPasswordMutationVariables = Exact<{
  forgotPasswordArgs: ForgotPasswordArgs;
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: boolean };

export type AddUserMutationVariables = Exact<{
  addUserArgs: AddUserArgs;
}>;


export type AddUserMutation = { __typename?: 'Mutation', addUser: boolean };

export type EditUserMutationVariables = Exact<{
  editUserArgs: EditUserArgs;
}>;


export type EditUserMutation = { __typename?: 'Mutation', editUser: boolean };

export type UpdateUserRoleMutationVariables = Exact<{
  updateUserRoleArgs: UpdateUserRoleArgs;
}>;


export type UpdateUserRoleMutation = { __typename?: 'Mutation', updateUserRole: boolean };

export type DeleteUserMutationVariables = Exact<{
  deleteUserArgs: DeleteUserArgs;
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: boolean };


export const GetPatientsByClientIdDocument = gql`
    query getPatientsByClientId($getPatientsByClientIdArgs: GetPatientsByClientIdArgs!) {
  getPatientsByClientId(getPatientsByClientIdArgs: $getPatientsByClientIdArgs) {
    id
    assignedUserId
    firstName
    lastName
    email
    phone
    ipAddress
    clientId
    caseNum
    middleName
    notes
  }
}
    `;

/**
 * __useGetPatientsByClientIdQuery__
 *
 * To run a query within a React component, call `useGetPatientsByClientIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientsByClientIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientsByClientIdQuery({
 *   variables: {
 *      getPatientsByClientIdArgs: // value for 'getPatientsByClientIdArgs'
 *   },
 * });
 */
export function useGetPatientsByClientIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetPatientsByClientIdQuery, GetPatientsByClientIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPatientsByClientIdQuery, GetPatientsByClientIdQueryVariables>(GetPatientsByClientIdDocument, options);
      }
export function useGetPatientsByClientIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPatientsByClientIdQuery, GetPatientsByClientIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPatientsByClientIdQuery, GetPatientsByClientIdQueryVariables>(GetPatientsByClientIdDocument, options);
        }
export type GetPatientsByClientIdQueryHookResult = ReturnType<typeof useGetPatientsByClientIdQuery>;
export type GetPatientsByClientIdLazyQueryHookResult = ReturnType<typeof useGetPatientsByClientIdLazyQuery>;
export type GetPatientsByClientIdQueryResult = ApolloReactCommon.QueryResult<GetPatientsByClientIdQuery, GetPatientsByClientIdQueryVariables>;
export const GetAllClientsDocument = gql`
    query getAllClients {
  getAllClients {
    id
    name
  }
}
    `;

/**
 * __useGetAllClientsQuery__
 *
 * To run a query within a React component, call `useGetAllClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllClientsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllClientsQuery, GetAllClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAllClientsQuery, GetAllClientsQueryVariables>(GetAllClientsDocument, options);
      }
export function useGetAllClientsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllClientsQuery, GetAllClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAllClientsQuery, GetAllClientsQueryVariables>(GetAllClientsDocument, options);
        }
export type GetAllClientsQueryHookResult = ReturnType<typeof useGetAllClientsQuery>;
export type GetAllClientsLazyQueryHookResult = ReturnType<typeof useGetAllClientsLazyQuery>;
export type GetAllClientsQueryResult = ApolloReactCommon.QueryResult<GetAllClientsQuery, GetAllClientsQueryVariables>;
export const GetClientDetailsDocument = gql`
    query getClientDetails($getClientDetailsArgs: GetClientDetailsArgs!) {
  getClientDetails(getClientDetailsArgs: $getClientDetailsArgs) {
    id
    name
    contactEmail
    number
    contactPerson
  }
}
    `;

/**
 * __useGetClientDetailsQuery__
 *
 * To run a query within a React component, call `useGetClientDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientDetailsQuery({
 *   variables: {
 *      getClientDetailsArgs: // value for 'getClientDetailsArgs'
 *   },
 * });
 */
export function useGetClientDetailsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetClientDetailsQuery, GetClientDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetClientDetailsQuery, GetClientDetailsQueryVariables>(GetClientDetailsDocument, options);
      }
export function useGetClientDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientDetailsQuery, GetClientDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetClientDetailsQuery, GetClientDetailsQueryVariables>(GetClientDetailsDocument, options);
        }
export type GetClientDetailsQueryHookResult = ReturnType<typeof useGetClientDetailsQuery>;
export type GetClientDetailsLazyQueryHookResult = ReturnType<typeof useGetClientDetailsLazyQuery>;
export type GetClientDetailsQueryResult = ApolloReactCommon.QueryResult<GetClientDetailsQuery, GetClientDetailsQueryVariables>;
export const AddClientDocument = gql`
    mutation addClient($addClientArgs: AddClientArgs!) {
  addClient(addClientArgs: $addClientArgs)
}
    `;
export type AddClientMutationFn = ApolloReactCommon.MutationFunction<AddClientMutation, AddClientMutationVariables>;

/**
 * __useAddClientMutation__
 *
 * To run a mutation, you first call `useAddClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClientMutation, { data, loading, error }] = useAddClientMutation({
 *   variables: {
 *      addClientArgs: // value for 'addClientArgs'
 *   },
 * });
 */
export function useAddClientMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddClientMutation, AddClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddClientMutation, AddClientMutationVariables>(AddClientDocument, options);
      }
export type AddClientMutationHookResult = ReturnType<typeof useAddClientMutation>;
export type AddClientMutationResult = ApolloReactCommon.MutationResult<AddClientMutation>;
export type AddClientMutationOptions = ApolloReactCommon.BaseMutationOptions<AddClientMutation, AddClientMutationVariables>;
export const UpdateClientDetailsDocument = gql`
    mutation updateClientDetails($updateClientDetailsArgs: UpdateClientDetailsArgs!) {
  updateClientDetails(updateClientDetailsArgs: $updateClientDetailsArgs)
}
    `;
export type UpdateClientDetailsMutationFn = ApolloReactCommon.MutationFunction<UpdateClientDetailsMutation, UpdateClientDetailsMutationVariables>;

/**
 * __useUpdateClientDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateClientDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientDetailsMutation, { data, loading, error }] = useUpdateClientDetailsMutation({
 *   variables: {
 *      updateClientDetailsArgs: // value for 'updateClientDetailsArgs'
 *   },
 * });
 */
export function useUpdateClientDetailsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateClientDetailsMutation, UpdateClientDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateClientDetailsMutation, UpdateClientDetailsMutationVariables>(UpdateClientDetailsDocument, options);
      }
export type UpdateClientDetailsMutationHookResult = ReturnType<typeof useUpdateClientDetailsMutation>;
export type UpdateClientDetailsMutationResult = ApolloReactCommon.MutationResult<UpdateClientDetailsMutation>;
export type UpdateClientDetailsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateClientDetailsMutation, UpdateClientDetailsMutationVariables>;
export const DeleteClientDocument = gql`
    mutation deleteClient($deleteClientArgs: DeleteClientArgs!) {
  deleteClient(deleteClientArgs: $deleteClientArgs)
}
    `;
export type DeleteClientMutationFn = ApolloReactCommon.MutationFunction<DeleteClientMutation, DeleteClientMutationVariables>;

/**
 * __useDeleteClientMutation__
 *
 * To run a mutation, you first call `useDeleteClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientMutation, { data, loading, error }] = useDeleteClientMutation({
 *   variables: {
 *      deleteClientArgs: // value for 'deleteClientArgs'
 *   },
 * });
 */
export function useDeleteClientMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteClientMutation, DeleteClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteClientMutation, DeleteClientMutationVariables>(DeleteClientDocument, options);
      }
export type DeleteClientMutationHookResult = ReturnType<typeof useDeleteClientMutation>;
export type DeleteClientMutationResult = ApolloReactCommon.MutationResult<DeleteClientMutation>;
export type DeleteClientMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteClientMutation, DeleteClientMutationVariables>;
export const AddClientRoleDocument = gql`
    mutation addClientRole($addClientRoleArgs: AddClientRoleArgs!) {
  addClientRole(addClientRoleArgs: $addClientRoleArgs) {
    id
    name
    isDefault
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
    `;
export type AddClientRoleMutationFn = ApolloReactCommon.MutationFunction<AddClientRoleMutation, AddClientRoleMutationVariables>;

/**
 * __useAddClientRoleMutation__
 *
 * To run a mutation, you first call `useAddClientRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClientRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClientRoleMutation, { data, loading, error }] = useAddClientRoleMutation({
 *   variables: {
 *      addClientRoleArgs: // value for 'addClientRoleArgs'
 *   },
 * });
 */
export function useAddClientRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddClientRoleMutation, AddClientRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddClientRoleMutation, AddClientRoleMutationVariables>(AddClientRoleDocument, options);
      }
export type AddClientRoleMutationHookResult = ReturnType<typeof useAddClientRoleMutation>;
export type AddClientRoleMutationResult = ApolloReactCommon.MutationResult<AddClientRoleMutation>;
export type AddClientRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<AddClientRoleMutation, AddClientRoleMutationVariables>;
export const GetClientRolesDocument = gql`
    query getClientRoles($getClientRolesArgs: GetClientRolesArgs!) {
  getClientRoles(getClientRolesArgs: $getClientRolesArgs) {
    id
    name
    isDefault
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetClientRolesQuery__
 *
 * To run a query within a React component, call `useGetClientRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientRolesQuery({
 *   variables: {
 *      getClientRolesArgs: // value for 'getClientRolesArgs'
 *   },
 * });
 */
export function useGetClientRolesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetClientRolesQuery, GetClientRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetClientRolesQuery, GetClientRolesQueryVariables>(GetClientRolesDocument, options);
      }
export function useGetClientRolesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientRolesQuery, GetClientRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetClientRolesQuery, GetClientRolesQueryVariables>(GetClientRolesDocument, options);
        }
export type GetClientRolesQueryHookResult = ReturnType<typeof useGetClientRolesQuery>;
export type GetClientRolesLazyQueryHookResult = ReturnType<typeof useGetClientRolesLazyQuery>;
export type GetClientRolesQueryResult = ApolloReactCommon.QueryResult<GetClientRolesQuery, GetClientRolesQueryVariables>;
export const GetClientRoleByIdDocument = gql`
    query getClientRoleById($getClientRoleByIdArgs: GetClientRolesByRoleIdArgs!) {
  getClientRoleById(getClientRoleByIdArgs: $getClientRoleByIdArgs) {
    id
    name
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetClientRoleByIdQuery__
 *
 * To run a query within a React component, call `useGetClientRoleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientRoleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientRoleByIdQuery({
 *   variables: {
 *      getClientRoleByIdArgs: // value for 'getClientRoleByIdArgs'
 *   },
 * });
 */
export function useGetClientRoleByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetClientRoleByIdQuery, GetClientRoleByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetClientRoleByIdQuery, GetClientRoleByIdQueryVariables>(GetClientRoleByIdDocument, options);
      }
export function useGetClientRoleByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientRoleByIdQuery, GetClientRoleByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetClientRoleByIdQuery, GetClientRoleByIdQueryVariables>(GetClientRoleByIdDocument, options);
        }
export type GetClientRoleByIdQueryHookResult = ReturnType<typeof useGetClientRoleByIdQuery>;
export type GetClientRoleByIdLazyQueryHookResult = ReturnType<typeof useGetClientRoleByIdLazyQuery>;
export type GetClientRoleByIdQueryResult = ApolloReactCommon.QueryResult<GetClientRoleByIdQuery, GetClientRoleByIdQueryVariables>;
export const GetClientRoleByUserIdDocument = gql`
    query getClientRoleByUserId($getClientRolesByUserIdArgs: GetClientRolesByUserIdArgs!) {
  getClientRoleByUserId(getClientRolesByUserIdArgs: $getClientRolesByUserIdArgs) {
    id
    name
    isDefault
    createdBy
    updatedBy
    createdAt
    updatedAt
    privileges {
      roleId
      feature
      create
      read
      update
      delete
      hidden
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetClientRoleByUserIdQuery__
 *
 * To run a query within a React component, call `useGetClientRoleByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientRoleByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientRoleByUserIdQuery({
 *   variables: {
 *      getClientRolesByUserIdArgs: // value for 'getClientRolesByUserIdArgs'
 *   },
 * });
 */
export function useGetClientRoleByUserIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetClientRoleByUserIdQuery, GetClientRoleByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetClientRoleByUserIdQuery, GetClientRoleByUserIdQueryVariables>(GetClientRoleByUserIdDocument, options);
      }
export function useGetClientRoleByUserIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientRoleByUserIdQuery, GetClientRoleByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetClientRoleByUserIdQuery, GetClientRoleByUserIdQueryVariables>(GetClientRoleByUserIdDocument, options);
        }
export type GetClientRoleByUserIdQueryHookResult = ReturnType<typeof useGetClientRoleByUserIdQuery>;
export type GetClientRoleByUserIdLazyQueryHookResult = ReturnType<typeof useGetClientRoleByUserIdLazyQuery>;
export type GetClientRoleByUserIdQueryResult = ApolloReactCommon.QueryResult<GetClientRoleByUserIdQuery, GetClientRoleByUserIdQueryVariables>;
export const GetClientRoleByUserIdsDocument = gql`
    query getClientRoleByUserIds($getClientRolesByUserIdsArgs: GetClientRolesByUserIdsArgs!) {
  getClientRoleByUserIds(
    getClientRolesByUserIdsArgs: $getClientRolesByUserIdsArgs
  ) {
    id
    userId
    name
    isDefault
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetClientRoleByUserIdsQuery__
 *
 * To run a query within a React component, call `useGetClientRoleByUserIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientRoleByUserIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientRoleByUserIdsQuery({
 *   variables: {
 *      getClientRolesByUserIdsArgs: // value for 'getClientRolesByUserIdsArgs'
 *   },
 * });
 */
export function useGetClientRoleByUserIdsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetClientRoleByUserIdsQuery, GetClientRoleByUserIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetClientRoleByUserIdsQuery, GetClientRoleByUserIdsQueryVariables>(GetClientRoleByUserIdsDocument, options);
      }
export function useGetClientRoleByUserIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientRoleByUserIdsQuery, GetClientRoleByUserIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetClientRoleByUserIdsQuery, GetClientRoleByUserIdsQueryVariables>(GetClientRoleByUserIdsDocument, options);
        }
export type GetClientRoleByUserIdsQueryHookResult = ReturnType<typeof useGetClientRoleByUserIdsQuery>;
export type GetClientRoleByUserIdsLazyQueryHookResult = ReturnType<typeof useGetClientRoleByUserIdsLazyQuery>;
export type GetClientRoleByUserIdsQueryResult = ApolloReactCommon.QueryResult<GetClientRoleByUserIdsQuery, GetClientRoleByUserIdsQueryVariables>;
export const GetClientRolePrivilegesDocument = gql`
    query getClientRolePrivileges($getClientRolePrivilegesArgs: GetClientRolePrivilegesArgs!) {
  getClientRolePrivileges(
    getClientRolePrivilegesArgs: $getClientRolePrivilegesArgs
  ) {
    roleId
    feature
    create
    read
    update
    delete
    hidden
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetClientRolePrivilegesQuery__
 *
 * To run a query within a React component, call `useGetClientRolePrivilegesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientRolePrivilegesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientRolePrivilegesQuery({
 *   variables: {
 *      getClientRolePrivilegesArgs: // value for 'getClientRolePrivilegesArgs'
 *   },
 * });
 */
export function useGetClientRolePrivilegesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetClientRolePrivilegesQuery, GetClientRolePrivilegesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetClientRolePrivilegesQuery, GetClientRolePrivilegesQueryVariables>(GetClientRolePrivilegesDocument, options);
      }
export function useGetClientRolePrivilegesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientRolePrivilegesQuery, GetClientRolePrivilegesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetClientRolePrivilegesQuery, GetClientRolePrivilegesQueryVariables>(GetClientRolePrivilegesDocument, options);
        }
export type GetClientRolePrivilegesQueryHookResult = ReturnType<typeof useGetClientRolePrivilegesQuery>;
export type GetClientRolePrivilegesLazyQueryHookResult = ReturnType<typeof useGetClientRolePrivilegesLazyQuery>;
export type GetClientRolePrivilegesQueryResult = ApolloReactCommon.QueryResult<GetClientRolePrivilegesQuery, GetClientRolePrivilegesQueryVariables>;
export const UpdateClientRoleDocument = gql`
    mutation updateClientRole($updateClientRoleArgs: UpdateClientRoleArgs!) {
  updateClientRole(updateClientRoleArgs: $updateClientRoleArgs) {
    id
    name
    isDefault
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
    `;
export type UpdateClientRoleMutationFn = ApolloReactCommon.MutationFunction<UpdateClientRoleMutation, UpdateClientRoleMutationVariables>;

/**
 * __useUpdateClientRoleMutation__
 *
 * To run a mutation, you first call `useUpdateClientRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientRoleMutation, { data, loading, error }] = useUpdateClientRoleMutation({
 *   variables: {
 *      updateClientRoleArgs: // value for 'updateClientRoleArgs'
 *   },
 * });
 */
export function useUpdateClientRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateClientRoleMutation, UpdateClientRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateClientRoleMutation, UpdateClientRoleMutationVariables>(UpdateClientRoleDocument, options);
      }
export type UpdateClientRoleMutationHookResult = ReturnType<typeof useUpdateClientRoleMutation>;
export type UpdateClientRoleMutationResult = ApolloReactCommon.MutationResult<UpdateClientRoleMutation>;
export type UpdateClientRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateClientRoleMutation, UpdateClientRoleMutationVariables>;
export const SetClientRolePrivilegesDocument = gql`
    mutation SetClientRolePrivileges($setClientRolePrivilegesArgs: SetClientRolePrivilegesArgs!) {
  setClientRolePrivileges(
    setClientRolePrivilegesArgs: $setClientRolePrivilegesArgs
  )
}
    `;
export type SetClientRolePrivilegesMutationFn = ApolloReactCommon.MutationFunction<SetClientRolePrivilegesMutation, SetClientRolePrivilegesMutationVariables>;

/**
 * __useSetClientRolePrivilegesMutation__
 *
 * To run a mutation, you first call `useSetClientRolePrivilegesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetClientRolePrivilegesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setClientRolePrivilegesMutation, { data, loading, error }] = useSetClientRolePrivilegesMutation({
 *   variables: {
 *      setClientRolePrivilegesArgs: // value for 'setClientRolePrivilegesArgs'
 *   },
 * });
 */
export function useSetClientRolePrivilegesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetClientRolePrivilegesMutation, SetClientRolePrivilegesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SetClientRolePrivilegesMutation, SetClientRolePrivilegesMutationVariables>(SetClientRolePrivilegesDocument, options);
      }
export type SetClientRolePrivilegesMutationHookResult = ReturnType<typeof useSetClientRolePrivilegesMutation>;
export type SetClientRolePrivilegesMutationResult = ApolloReactCommon.MutationResult<SetClientRolePrivilegesMutation>;
export type SetClientRolePrivilegesMutationOptions = ApolloReactCommon.BaseMutationOptions<SetClientRolePrivilegesMutation, SetClientRolePrivilegesMutationVariables>;
export const AssignClientRoleDocument = gql`
    mutation AssignClientRole($assignClientRoleArgs: AssignClientRoleArgs!) {
  assignClientRole(assignClientRoleArgs: $assignClientRoleArgs)
}
    `;
export type AssignClientRoleMutationFn = ApolloReactCommon.MutationFunction<AssignClientRoleMutation, AssignClientRoleMutationVariables>;

/**
 * __useAssignClientRoleMutation__
 *
 * To run a mutation, you first call `useAssignClientRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignClientRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignClientRoleMutation, { data, loading, error }] = useAssignClientRoleMutation({
 *   variables: {
 *      assignClientRoleArgs: // value for 'assignClientRoleArgs'
 *   },
 * });
 */
export function useAssignClientRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignClientRoleMutation, AssignClientRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AssignClientRoleMutation, AssignClientRoleMutationVariables>(AssignClientRoleDocument, options);
      }
export type AssignClientRoleMutationHookResult = ReturnType<typeof useAssignClientRoleMutation>;
export type AssignClientRoleMutationResult = ApolloReactCommon.MutationResult<AssignClientRoleMutation>;
export type AssignClientRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignClientRoleMutation, AssignClientRoleMutationVariables>;
export const DeleteClientRoleDocument = gql`
    mutation deleteClientRole($deleteClientRoleArgs: DeleteClientRoleArgs!) {
  deleteClientRole(deleteClientRoleArgs: $deleteClientRoleArgs)
}
    `;
export type DeleteClientRoleMutationFn = ApolloReactCommon.MutationFunction<DeleteClientRoleMutation, DeleteClientRoleMutationVariables>;

/**
 * __useDeleteClientRoleMutation__
 *
 * To run a mutation, you first call `useDeleteClientRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientRoleMutation, { data, loading, error }] = useDeleteClientRoleMutation({
 *   variables: {
 *      deleteClientRoleArgs: // value for 'deleteClientRoleArgs'
 *   },
 * });
 */
export function useDeleteClientRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteClientRoleMutation, DeleteClientRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteClientRoleMutation, DeleteClientRoleMutationVariables>(DeleteClientRoleDocument, options);
      }
export type DeleteClientRoleMutationHookResult = ReturnType<typeof useDeleteClientRoleMutation>;
export type DeleteClientRoleMutationResult = ApolloReactCommon.MutationResult<DeleteClientRoleMutation>;
export type DeleteClientRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteClientRoleMutation, DeleteClientRoleMutationVariables>;
export const GetClientRoleAccessByFeatureKeyDocument = gql`
    query GetClientRoleAccessByFeatureKey($getClientRoleAccessByFeatureKeyArgs: GetClientRoleAccessByFeatureKeyArgs!) {
  getClientRoleAccessByFeatureKey(
    getClientRoleAccessByFeatureKeyArgs: $getClientRoleAccessByFeatureKeyArgs
  )
}
    `;

/**
 * __useGetClientRoleAccessByFeatureKeyQuery__
 *
 * To run a query within a React component, call `useGetClientRoleAccessByFeatureKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientRoleAccessByFeatureKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientRoleAccessByFeatureKeyQuery({
 *   variables: {
 *      getClientRoleAccessByFeatureKeyArgs: // value for 'getClientRoleAccessByFeatureKeyArgs'
 *   },
 * });
 */
export function useGetClientRoleAccessByFeatureKeyQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetClientRoleAccessByFeatureKeyQuery, GetClientRoleAccessByFeatureKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetClientRoleAccessByFeatureKeyQuery, GetClientRoleAccessByFeatureKeyQueryVariables>(GetClientRoleAccessByFeatureKeyDocument, options);
      }
export function useGetClientRoleAccessByFeatureKeyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientRoleAccessByFeatureKeyQuery, GetClientRoleAccessByFeatureKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetClientRoleAccessByFeatureKeyQuery, GetClientRoleAccessByFeatureKeyQueryVariables>(GetClientRoleAccessByFeatureKeyDocument, options);
        }
export type GetClientRoleAccessByFeatureKeyQueryHookResult = ReturnType<typeof useGetClientRoleAccessByFeatureKeyQuery>;
export type GetClientRoleAccessByFeatureKeyLazyQueryHookResult = ReturnType<typeof useGetClientRoleAccessByFeatureKeyLazyQuery>;
export type GetClientRoleAccessByFeatureKeyQueryResult = ApolloReactCommon.QueryResult<GetClientRoleAccessByFeatureKeyQuery, GetClientRoleAccessByFeatureKeyQueryVariables>;
export const GetCurrentUserClientRolesDocument = gql`
    query getCurrentUserClientRoles($getCurrentUserClientRolesArgs: GetCurrentUserClientRolesArgs!) {
  getCurrentUserClientRoles(
    getCurrentUserClientRolesArgs: $getCurrentUserClientRolesArgs
  ) {
    feature
    create
    read
    update
    delete
  }
}
    `;

/**
 * __useGetCurrentUserClientRolesQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserClientRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserClientRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserClientRolesQuery({
 *   variables: {
 *      getCurrentUserClientRolesArgs: // value for 'getCurrentUserClientRolesArgs'
 *   },
 * });
 */
export function useGetCurrentUserClientRolesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCurrentUserClientRolesQuery, GetCurrentUserClientRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCurrentUserClientRolesQuery, GetCurrentUserClientRolesQueryVariables>(GetCurrentUserClientRolesDocument, options);
      }
export function useGetCurrentUserClientRolesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrentUserClientRolesQuery, GetCurrentUserClientRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCurrentUserClientRolesQuery, GetCurrentUserClientRolesQueryVariables>(GetCurrentUserClientRolesDocument, options);
        }
export type GetCurrentUserClientRolesQueryHookResult = ReturnType<typeof useGetCurrentUserClientRolesQuery>;
export type GetCurrentUserClientRolesLazyQueryHookResult = ReturnType<typeof useGetCurrentUserClientRolesLazyQuery>;
export type GetCurrentUserClientRolesQueryResult = ApolloReactCommon.QueryResult<GetCurrentUserClientRolesQuery, GetCurrentUserClientRolesQueryVariables>;
export const AuthenticateUserDocument = gql`
    mutation authenticateUser($authenticateUserArgs: AuthenticateUserArgs!) {
  authenticateUser(authenticateUserArgs: $authenticateUserArgs)
}
    `;
export type AuthenticateUserMutationFn = ApolloReactCommon.MutationFunction<AuthenticateUserMutation, AuthenticateUserMutationVariables>;

/**
 * __useAuthenticateUserMutation__
 *
 * To run a mutation, you first call `useAuthenticateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateUserMutation, { data, loading, error }] = useAuthenticateUserMutation({
 *   variables: {
 *      authenticateUserArgs: // value for 'authenticateUserArgs'
 *   },
 * });
 */
export function useAuthenticateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuthenticateUserMutation, AuthenticateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AuthenticateUserMutation, AuthenticateUserMutationVariables>(AuthenticateUserDocument, options);
      }
export type AuthenticateUserMutationHookResult = ReturnType<typeof useAuthenticateUserMutation>;
export type AuthenticateUserMutationResult = ApolloReactCommon.MutationResult<AuthenticateUserMutation>;
export type AuthenticateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<AuthenticateUserMutation, AuthenticateUserMutationVariables>;
export const VerifyUserOtpDocument = gql`
    mutation verifyUserOtp($verifyUserOtpArgs: VerifyUserOtpArgs!) {
  verifyUserOtp(verifyUserOtpArgs: $verifyUserOtpArgs)
}
    `;
export type VerifyUserOtpMutationFn = ApolloReactCommon.MutationFunction<VerifyUserOtpMutation, VerifyUserOtpMutationVariables>;

/**
 * __useVerifyUserOtpMutation__
 *
 * To run a mutation, you first call `useVerifyUserOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyUserOtpMutation, { data, loading, error }] = useVerifyUserOtpMutation({
 *   variables: {
 *      verifyUserOtpArgs: // value for 'verifyUserOtpArgs'
 *   },
 * });
 */
export function useVerifyUserOtpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyUserOtpMutation, VerifyUserOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<VerifyUserOtpMutation, VerifyUserOtpMutationVariables>(VerifyUserOtpDocument, options);
      }
export type VerifyUserOtpMutationHookResult = ReturnType<typeof useVerifyUserOtpMutation>;
export type VerifyUserOtpMutationResult = ApolloReactCommon.MutationResult<VerifyUserOtpMutation>;
export type VerifyUserOtpMutationOptions = ApolloReactCommon.BaseMutationOptions<VerifyUserOtpMutation, VerifyUserOtpMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($resetPasswordArgs: ResetPasswordArgs!) {
  resetPassword(resetPasswordArgs: $resetPasswordArgs)
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      resetPasswordArgs: // value for 'resetPasswordArgs'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation refreshToken($refreshTokenArgs: RefreshTokenArgs!) {
  refreshToken(refreshTokenArgs: $refreshTokenArgs)
}
    `;
export type RefreshTokenMutationFn = ApolloReactCommon.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refreshTokenArgs: // value for 'refreshTokenArgs'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = ApolloReactCommon.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const DeleteTokenDocument = gql`
    mutation deleteToken($deleteTokenArgs: DeleteTokenArgs!) {
  deleteToken(deleteTokenArgs: $deleteTokenArgs)
}
    `;
export type DeleteTokenMutationFn = ApolloReactCommon.MutationFunction<DeleteTokenMutation, DeleteTokenMutationVariables>;

/**
 * __useDeleteTokenMutation__
 *
 * To run a mutation, you first call `useDeleteTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTokenMutation, { data, loading, error }] = useDeleteTokenMutation({
 *   variables: {
 *      deleteTokenArgs: // value for 'deleteTokenArgs'
 *   },
 * });
 */
export function useDeleteTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTokenMutation, DeleteTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteTokenMutation, DeleteTokenMutationVariables>(DeleteTokenDocument, options);
      }
export type DeleteTokenMutationHookResult = ReturnType<typeof useDeleteTokenMutation>;
export type DeleteTokenMutationResult = ApolloReactCommon.MutationResult<DeleteTokenMutation>;
export type DeleteTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTokenMutation, DeleteTokenMutationVariables>;
export const GetNotificationsByIdDocument = gql`
    query getNotificationsById($getNotificationsByIdArgs: GetNotificationsByIdArgs!) {
  getNotificationsById(getNotificationsByIdArgs: $getNotificationsByIdArgs) {
    id
    userId
    title
    message
    read
    url
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetNotificationsByIdQuery__
 *
 * To run a query within a React component, call `useGetNotificationsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsByIdQuery({
 *   variables: {
 *      getNotificationsByIdArgs: // value for 'getNotificationsByIdArgs'
 *   },
 * });
 */
export function useGetNotificationsByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetNotificationsByIdQuery, GetNotificationsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetNotificationsByIdQuery, GetNotificationsByIdQueryVariables>(GetNotificationsByIdDocument, options);
      }
export function useGetNotificationsByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNotificationsByIdQuery, GetNotificationsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetNotificationsByIdQuery, GetNotificationsByIdQueryVariables>(GetNotificationsByIdDocument, options);
        }
export type GetNotificationsByIdQueryHookResult = ReturnType<typeof useGetNotificationsByIdQuery>;
export type GetNotificationsByIdLazyQueryHookResult = ReturnType<typeof useGetNotificationsByIdLazyQuery>;
export type GetNotificationsByIdQueryResult = ApolloReactCommon.QueryResult<GetNotificationsByIdQuery, GetNotificationsByIdQueryVariables>;
export const UpdateNotificationByIdDocument = gql`
    mutation updateNotificationById($updateNotificationByIdArgs: UpdateNotificationByIdArgs!) {
  updateNotificationById(updateNotificationByIdArgs: $updateNotificationByIdArgs)
}
    `;
export type UpdateNotificationByIdMutationFn = ApolloReactCommon.MutationFunction<UpdateNotificationByIdMutation, UpdateNotificationByIdMutationVariables>;

/**
 * __useUpdateNotificationByIdMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationByIdMutation, { data, loading, error }] = useUpdateNotificationByIdMutation({
 *   variables: {
 *      updateNotificationByIdArgs: // value for 'updateNotificationByIdArgs'
 *   },
 * });
 */
export function useUpdateNotificationByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNotificationByIdMutation, UpdateNotificationByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateNotificationByIdMutation, UpdateNotificationByIdMutationVariables>(UpdateNotificationByIdDocument, options);
      }
export type UpdateNotificationByIdMutationHookResult = ReturnType<typeof useUpdateNotificationByIdMutation>;
export type UpdateNotificationByIdMutationResult = ApolloReactCommon.MutationResult<UpdateNotificationByIdMutation>;
export type UpdateNotificationByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateNotificationByIdMutation, UpdateNotificationByIdMutationVariables>;
export const UpdateNotificationAllDocument = gql`
    mutation updateNotificationAll($updateNotificationAllArgs: UpdateNotificationAllArgs!) {
  updateNotificationAll(updateNotificationAllArgs: $updateNotificationAllArgs)
}
    `;
export type UpdateNotificationAllMutationFn = ApolloReactCommon.MutationFunction<UpdateNotificationAllMutation, UpdateNotificationAllMutationVariables>;

/**
 * __useUpdateNotificationAllMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationAllMutation, { data, loading, error }] = useUpdateNotificationAllMutation({
 *   variables: {
 *      updateNotificationAllArgs: // value for 'updateNotificationAllArgs'
 *   },
 * });
 */
export function useUpdateNotificationAllMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNotificationAllMutation, UpdateNotificationAllMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateNotificationAllMutation, UpdateNotificationAllMutationVariables>(UpdateNotificationAllDocument, options);
      }
export type UpdateNotificationAllMutationHookResult = ReturnType<typeof useUpdateNotificationAllMutation>;
export type UpdateNotificationAllMutationResult = ApolloReactCommon.MutationResult<UpdateNotificationAllMutation>;
export type UpdateNotificationAllMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateNotificationAllMutation, UpdateNotificationAllMutationVariables>;
export const GetQuicklinksDocument = gql`
    query getQuicklinks($getQuicklinksArgs: GetQuicklinksArgs!) {
  getQuicklinks(getQuicklinks: $getQuicklinksArgs) {
    id
    title
    url
    description
    createdById
    createdBy
    updatedById
    updatedBy
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetQuicklinksQuery__
 *
 * To run a query within a React component, call `useGetQuicklinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuicklinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuicklinksQuery({
 *   variables: {
 *      getQuicklinksArgs: // value for 'getQuicklinksArgs'
 *   },
 * });
 */
export function useGetQuicklinksQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetQuicklinksQuery, GetQuicklinksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetQuicklinksQuery, GetQuicklinksQueryVariables>(GetQuicklinksDocument, options);
      }
export function useGetQuicklinksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetQuicklinksQuery, GetQuicklinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetQuicklinksQuery, GetQuicklinksQueryVariables>(GetQuicklinksDocument, options);
        }
export type GetQuicklinksQueryHookResult = ReturnType<typeof useGetQuicklinksQuery>;
export type GetQuicklinksLazyQueryHookResult = ReturnType<typeof useGetQuicklinksLazyQuery>;
export type GetQuicklinksQueryResult = ApolloReactCommon.QueryResult<GetQuicklinksQuery, GetQuicklinksQueryVariables>;
export const GetQuicklinkByIdDocument = gql`
    query getQuicklinkById($getQuicklinkByIdArgs: GetQuicklinkByIdArgs!) {
  getQuicklinkById(getQuicklinkById: $getQuicklinkByIdArgs) {
    id
    title
    url
    description
    createdById
    createdBy
    updatedById
    updatedBy
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetQuicklinkByIdQuery__
 *
 * To run a query within a React component, call `useGetQuicklinkByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuicklinkByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuicklinkByIdQuery({
 *   variables: {
 *      getQuicklinkByIdArgs: // value for 'getQuicklinkByIdArgs'
 *   },
 * });
 */
export function useGetQuicklinkByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetQuicklinkByIdQuery, GetQuicklinkByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetQuicklinkByIdQuery, GetQuicklinkByIdQueryVariables>(GetQuicklinkByIdDocument, options);
      }
export function useGetQuicklinkByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetQuicklinkByIdQuery, GetQuicklinkByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetQuicklinkByIdQuery, GetQuicklinkByIdQueryVariables>(GetQuicklinkByIdDocument, options);
        }
export type GetQuicklinkByIdQueryHookResult = ReturnType<typeof useGetQuicklinkByIdQuery>;
export type GetQuicklinkByIdLazyQueryHookResult = ReturnType<typeof useGetQuicklinkByIdLazyQuery>;
export type GetQuicklinkByIdQueryResult = ApolloReactCommon.QueryResult<GetQuicklinkByIdQuery, GetQuicklinkByIdQueryVariables>;
export const UpdateQuickLinkByIdDocument = gql`
    mutation updateQuickLinkById($updateQuicklinkByIdArgs: UpdateQuicklinkByIdArgs!) {
  updateQuickLinkById(updateQuickLinkById: $updateQuicklinkByIdArgs)
}
    `;
export type UpdateQuickLinkByIdMutationFn = ApolloReactCommon.MutationFunction<UpdateQuickLinkByIdMutation, UpdateQuickLinkByIdMutationVariables>;

/**
 * __useUpdateQuickLinkByIdMutation__
 *
 * To run a mutation, you first call `useUpdateQuickLinkByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuickLinkByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuickLinkByIdMutation, { data, loading, error }] = useUpdateQuickLinkByIdMutation({
 *   variables: {
 *      updateQuicklinkByIdArgs: // value for 'updateQuicklinkByIdArgs'
 *   },
 * });
 */
export function useUpdateQuickLinkByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateQuickLinkByIdMutation, UpdateQuickLinkByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateQuickLinkByIdMutation, UpdateQuickLinkByIdMutationVariables>(UpdateQuickLinkByIdDocument, options);
      }
export type UpdateQuickLinkByIdMutationHookResult = ReturnType<typeof useUpdateQuickLinkByIdMutation>;
export type UpdateQuickLinkByIdMutationResult = ApolloReactCommon.MutationResult<UpdateQuickLinkByIdMutation>;
export type UpdateQuickLinkByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateQuickLinkByIdMutation, UpdateQuickLinkByIdMutationVariables>;
export const AddQuickLinkDocument = gql`
    mutation addQuickLink($addQuicklinkArgs: AddQuicklinkArgs!) {
  addQuickLink(addQuickLink: $addQuicklinkArgs)
}
    `;
export type AddQuickLinkMutationFn = ApolloReactCommon.MutationFunction<AddQuickLinkMutation, AddQuickLinkMutationVariables>;

/**
 * __useAddQuickLinkMutation__
 *
 * To run a mutation, you first call `useAddQuickLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddQuickLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addQuickLinkMutation, { data, loading, error }] = useAddQuickLinkMutation({
 *   variables: {
 *      addQuicklinkArgs: // value for 'addQuicklinkArgs'
 *   },
 * });
 */
export function useAddQuickLinkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddQuickLinkMutation, AddQuickLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddQuickLinkMutation, AddQuickLinkMutationVariables>(AddQuickLinkDocument, options);
      }
export type AddQuickLinkMutationHookResult = ReturnType<typeof useAddQuickLinkMutation>;
export type AddQuickLinkMutationResult = ApolloReactCommon.MutationResult<AddQuickLinkMutation>;
export type AddQuickLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<AddQuickLinkMutation, AddQuickLinkMutationVariables>;
export const DeleteQuickLinkByIdDocument = gql`
    mutation deleteQuickLinkById($deleteQuicklinkByIdArgs: DeleteQuicklinkByIdArgs!) {
  deleteQuickLinkById(deleteQuickLinkById: $deleteQuicklinkByIdArgs)
}
    `;
export type DeleteQuickLinkByIdMutationFn = ApolloReactCommon.MutationFunction<DeleteQuickLinkByIdMutation, DeleteQuickLinkByIdMutationVariables>;

/**
 * __useDeleteQuickLinkByIdMutation__
 *
 * To run a mutation, you first call `useDeleteQuickLinkByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuickLinkByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuickLinkByIdMutation, { data, loading, error }] = useDeleteQuickLinkByIdMutation({
 *   variables: {
 *      deleteQuicklinkByIdArgs: // value for 'deleteQuicklinkByIdArgs'
 *   },
 * });
 */
export function useDeleteQuickLinkByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteQuickLinkByIdMutation, DeleteQuickLinkByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteQuickLinkByIdMutation, DeleteQuickLinkByIdMutationVariables>(DeleteQuickLinkByIdDocument, options);
      }
export type DeleteQuickLinkByIdMutationHookResult = ReturnType<typeof useDeleteQuickLinkByIdMutation>;
export type DeleteQuickLinkByIdMutationResult = ApolloReactCommon.MutationResult<DeleteQuickLinkByIdMutation>;
export type DeleteQuickLinkByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteQuickLinkByIdMutation, DeleteQuickLinkByIdMutationVariables>;
export const GetAllUsersDocument = gql`
    query getAllUsers($getAllUsersArgs: GetAllUsersArgs!) {
  getAllUsers(getAllUsersArgs: $getAllUsersArgs) {
    id
    clientId
    clientName
    firstName
    lastName
    email
    privileges
    role
  }
}
    `;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *      getAllUsersArgs: // value for 'getAllUsersArgs'
 *   },
 * });
 */
export function useGetAllUsersQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
      }
export function useGetAllUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
        }
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<typeof useGetAllUsersLazyQuery>;
export type GetAllUsersQueryResult = ApolloReactCommon.QueryResult<GetAllUsersQuery, GetAllUsersQueryVariables>;
export const GetAllUserRolesDocument = gql`
    query getAllUserRoles {
  getAllUserRoles
}
    `;

/**
 * __useGetAllUserRolesQuery__
 *
 * To run a query within a React component, call `useGetAllUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUserRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUserRolesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllUserRolesQuery, GetAllUserRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAllUserRolesQuery, GetAllUserRolesQueryVariables>(GetAllUserRolesDocument, options);
      }
export function useGetAllUserRolesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllUserRolesQuery, GetAllUserRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAllUserRolesQuery, GetAllUserRolesQueryVariables>(GetAllUserRolesDocument, options);
        }
export type GetAllUserRolesQueryHookResult = ReturnType<typeof useGetAllUserRolesQuery>;
export type GetAllUserRolesLazyQueryHookResult = ReturnType<typeof useGetAllUserRolesLazyQuery>;
export type GetAllUserRolesQueryResult = ApolloReactCommon.QueryResult<GetAllUserRolesQuery, GetAllUserRolesQueryVariables>;
export const GetUserByIdDocument = gql`
    query getUserById($getUserByIdArgs: GetUserByIdArgs!) {
  getUserById(getUserByIdArgs: $getUserByIdArgs) {
    id
    clientId
    clientName
    firstName
    lastName
    email
    privileges
    role
  }
}
    `;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      getUserByIdArgs: // value for 'getUserByIdArgs'
 *   },
 * });
 */
export function useGetUserByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
      }
export function useGetUserByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
        }
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>;
export type GetUserByIdQueryResult = ApolloReactCommon.QueryResult<GetUserByIdQuery, GetUserByIdQueryVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($forgotPasswordArgs: ForgotPasswordArgs!) {
  forgotPassword(forgotPasswordArgs: $forgotPasswordArgs)
}
    `;
export type ForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      forgotPasswordArgs: // value for 'forgotPasswordArgs'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = ApolloReactCommon.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const AddUserDocument = gql`
    mutation addUser($addUserArgs: AddUserArgs!) {
  addUser(addUserArgs: $addUserArgs)
}
    `;
export type AddUserMutationFn = ApolloReactCommon.MutationFunction<AddUserMutation, AddUserMutationVariables>;

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      addUserArgs: // value for 'addUserArgs'
 *   },
 * });
 */
export function useAddUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddUserMutation, AddUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument, options);
      }
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>;
export type AddUserMutationResult = ApolloReactCommon.MutationResult<AddUserMutation>;
export type AddUserMutationOptions = ApolloReactCommon.BaseMutationOptions<AddUserMutation, AddUserMutationVariables>;
export const EditUserDocument = gql`
    mutation editUser($editUserArgs: EditUserArgs!) {
  editUser(editUserArgs: $editUserArgs)
}
    `;
export type EditUserMutationFn = ApolloReactCommon.MutationFunction<EditUserMutation, EditUserMutationVariables>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      editUserArgs: // value for 'editUserArgs'
 *   },
 * });
 */
export function useEditUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditUserMutation, EditUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditUserMutation, EditUserMutationVariables>(EditUserDocument, options);
      }
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = ApolloReactCommon.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = ApolloReactCommon.BaseMutationOptions<EditUserMutation, EditUserMutationVariables>;
export const UpdateUserRoleDocument = gql`
    mutation updateUserRole($updateUserRoleArgs: UpdateUserRoleArgs!) {
  updateUserRole(updateUserRoleArgs: $updateUserRoleArgs)
}
    `;
export type UpdateUserRoleMutationFn = ApolloReactCommon.MutationFunction<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;

/**
 * __useUpdateUserRoleMutation__
 *
 * To run a mutation, you first call `useUpdateUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRoleMutation, { data, loading, error }] = useUpdateUserRoleMutation({
 *   variables: {
 *      updateUserRoleArgs: // value for 'updateUserRoleArgs'
 *   },
 * });
 */
export function useUpdateUserRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>(UpdateUserRoleDocument, options);
      }
export type UpdateUserRoleMutationHookResult = ReturnType<typeof useUpdateUserRoleMutation>;
export type UpdateUserRoleMutationResult = ApolloReactCommon.MutationResult<UpdateUserRoleMutation>;
export type UpdateUserRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($deleteUserArgs: DeleteUserArgs!) {
  deleteUser(deleteUserArgs: $deleteUserArgs)
}
    `;
export type DeleteUserMutationFn = ApolloReactCommon.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      deleteUserArgs: // value for 'deleteUserArgs'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = ApolloReactCommon.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;